// UserProgressHelper.ts
import {ALL_ACTION_ITEMS, ALL_CONTENT} from "../constants/LearningPathsConfig";


/**
 * Builds the default user progress object:
 * {
 *   courses: [
 *     {
 *       id: numberOrString,
 *       hasCompleted: false,
 *       lessons: [
 *         { id: numberOrString, hasCompleted: false, isInRoadmap: true },
 *         ...
 *       ]
 *     },
 *     ...
 *   ],
 *   interviews: [
 *     { id: ..., hasCompleted: false, isInRoadmap: true },
 *     ...
 *   ],
 *   resources: [
 *     { id: ..., hasCompleted: false, isInRoadmap: true },
 *     ...
 *   ],
 *   tools: [
 *     { id: ..., hasCompleted: false, isInRoadmap: true },
 *     ...
 *   ]
 * }
 */
export function getDefaultUserProgress(userId: string) {
    return {
        userId: userId,
        courses: buildDefaultCourses(),
        interviews: buildDefaultInterviews(),
        resources: buildDefaultResources(),
        tools: buildDefaultTools(),
        actions: buildDefaultActions()
    };
}

function buildDefaultCourses() {
    const result: any[] = [];
    ALL_CONTENT.allCourses.forEach((category) => {
        category.content.forEach((course) => {
            const courseProgress: any = {
                id: course.trackingId,
                hasCompleted: false,
                displayInRoadMap: true,
                lessons: []
            };

            course.sections.forEach((section) => {
                section.lessons.forEach((lesson) => {
                    courseProgress.lessons.push({
                        id: lesson.id,
                        hasCompleted: false,
                        displayInRoadMap: true
                    });
                });
            });

            result.push(courseProgress);
        });
    });
    return result;
}

function buildDefaultInterviews() {
    const result: any[] = [];
    // ALL_CONTENT.allInterviews is an array of categories; each category has .content (the interview objects)
    ALL_CONTENT.allInterviews.forEach((category) => {
        category.content.forEach((interview) => {
            result.push({
                id: interview.trackingId,
                hasCompleted: false,
                displayInRoadMap: true
            });
        });
    });
    return result;
}

function buildDefaultResources() {
    const result: any[] = [];
    ALL_CONTENT.allResources.forEach((category) => {
        category.content.forEach((resource) => {
            result.push({
                id: resource.trackingId,
                hasCompleted: false,
                displayInRoadMap: true
            });
        });
    });
    return result;
}

function buildDefaultTools() {
    const result: any[] = [];
    ALL_CONTENT.allAITools.forEach((category) => {
        category.content.forEach((tool) => {
            result.push({
                id: tool.trackingId,
                hasCompleted: false,
                displayInRoadMap: true
            });
        });
    });
    return result;
}

function buildDefaultActions() {
    const result: any[] = [];
    Object.values(ALL_ACTION_ITEMS).forEach((action) => {
        result.push({
            id: action.trackingId,
            hasCompleted: false,
            displayInRoadMap: true
        });
    });
    return result;
}

export function handleOnboardData(onboardingAnswers: any, onboardData: any) {
    console.log(onboardingAnswers);
    console.log(onboardData)
    if (onboardingAnswers[0] !== 0) {
        onboardData.courses.find((course: any) => course.id === "BecomeProgrammer").hasCompleted = true;
        onboardData.interviews.find((interview: any) => interview.id === "ElementaryToEngineer").hasCompleted = true;
        onboardData.courses.find((course: any) => course.id === "ChooseFirstLanguage").hasCompleted = true;
        onboardData.actions.find((action: any) => action.id === "findCourse").hasCompleted = true;
        if (onboardingAnswers[0] !== 1) {
            onboardData.actions.find((action: any) => action.id === "buildFirstProject").hasCompleted = true;
            onboardData.resources.find((resource: any) => resource.id === "DataTypesCheatSheet").hasCompleted = true;
            onboardData.resources.find((resource: any) => resource.id === "OperatorsCheatSheet").hasCompleted = true;
        }
        if (onboardingAnswers[0] === 3) {
            onboardData.actions.find((action: any) => action.id === "uploadProjectGithub").hasCompleted = true;
            onboardData.courses.find((course: any) => course.id === "DevGuideToProjectManagement").hasCompleted = true;
            onboardData.courses.find((course: any) => course.id === "WhatAreApis").hasCompleted = true;
        }
    }
    if (onboardingAnswers[1] !== 7) {
        onboardData.interviews.find((interview: any) => interview.id === "MockInterviewUnrelatedField").displayInRoadMap = false;
    }
    onboardData.resources.find((resource: any) => resource.id === "AdvancedDataStructures").displayInRoadMap = false;
    if (onboardingAnswers[2] === 10
        || onboardingAnswers[2] === 11
        || onboardingAnswers[2] === 12) {
        onboardData.resources.find((resource: any) => resource.id === "SqlCheatSheet").displayInRoadMap = false;
        onboardData.resources.find((resource: any) => resource.id === "PythonCheatSheet").displayInRoadMap = false;
    }
    onboardData.interviews.find((interview: any) => interview.id === "MockInterviewMidLevel").displayInRoadMap = false;
    return onboardData

}

export function isInUsersRoadmap(userProgressData: any, content: any, type: string) {
    if (!userProgressData || !content) return false
    switch (type) {
        case "courses":
            const course = userProgressData.courses.find((course: any) => content?.trackingId === course.id);
            return course ? course.displayInRoadMap : content.alwaysInRoadmap;
        case "interviews":
            const interview = userProgressData.interviews.find((interview: any) => content?.trackingId === interview.id);
            return interview ? interview.displayInRoadMap : content.alwaysInRoadmap;
        case "resources":
            const resource = userProgressData.resources.find((resource: any) => content?.trackingId === resource.id);
            return resource ? resource.displayInRoadMap : content.alwaysInRoadmap;
        case "tools":
            const tool = userProgressData.tools.find((tool: any) => content?.trackingId === tool.id);
            return tool ? tool.displayInRoadMap : content.alwaysInRoadmap;
        case "actions":
            const action = userProgressData.actions.find((action: any) => content?.trackingId === action.id);
            return action ? action.displayInRoadMap : content.alwaysInRoadmap;
    }
    return content.alwaysInRoadmap
}

export function hasUserCompleted(userProgressData: any, content: any, type: string) {
    if (!userProgressData) return false
    switch (type) {
        case "courses":
            const course = userProgressData.courses.find((course: any) => content?.trackingId === course.id);
            return course ? course.hasCompleted : false;
        case "interviews":
            const interview = userProgressData.interviews.find((interview: any) => content?.trackingId === interview.id);
            return interview ? interview.hasCompleted : false;
        case "resources":
            const resource = userProgressData.resources.find((resource: any) => content?.trackingId === resource.id);
            return resource ? resource.hasCompleted : false;
        case "tools":
            const tool = userProgressData.tools.find((tool: any) => content?.trackingId === tool.id);
            return tool ? tool.hasCompleted : false;
        case "actions":
            const action = userProgressData.actions.find((action: any) => content?.trackingId === action.id);
            return action ? action.hasCompleted : false;
    }
    return false;
}

export function getUserProgress(userProgressData: any) {
    if (!userProgressData) return 0;

    let totalItems = 0;
    let completedItems = 0;

    const progressTypes = ["courses", "interviews", "resources", "tools"];

    progressTypes.forEach((type) => {
        if (userProgressData[type] && Array.isArray(userProgressData[type])) {
            userProgressData[type].forEach((item: any) => {
                if (item.displayInRoadMap) {
                    totalItems++;
                    if (item.hasCompleted) {
                        completedItems++;
                    }
                }
            });
        }
    });

    return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
}