import { Interview } from "../models/Interview";
import ElementaryToEngineerThumbnail from "../images/interviews/From Elementary School Teacher to Software Engineer.webp";
import CommunityCollegeToBigTechThumbnail from "../images/interviews/From Community College to Success in Big Tech.webp";
import FindingMeaningfulWorkThumbnail from "../images/interviews/Finding Meaningful Work - What Makes a Successful Engineer.webp";
import MockInterviewEntryLevelThumbnail from "../images/interviews/Mock Interview (Non Technical)_ Entry Level Position.webp";
import MockInterviewMidLevelThumbnail from "../images/interviews/Mock Interview (Non Technical)_ Mid Level Position.webp";
import MockInterviewUnrelatedFieldThumbnail from "../images/interviews/Mock Interview_ Coming From an Unrelated Field.webp";

export const ALL_INTERVIEWS = {
    FORMAL_INTERVIEWS: [
        new Interview(
            "From Elementary School Teacher to Software Engineer",
            "An interview on transitioning from teaching to software engineering",
            "26 mins",
            ElementaryToEngineerThumbnail,
            "elementary-to-engineer",
            "from_elementary_school_teacher_to_software_engineer.mp4",
            "ElementaryToEngineer",
            false,
            true
        ),
        new Interview(
            "From Community College to Success in Big Tech",
            "An interview about making the leap from community college to big tech",
            "17 mins",
            CommunityCollegeToBigTechThumbnail,
            "community-college-to-big-tech",
            "clyde,_big_tech_company.mp4",
            "CommunityCollegeToBigTech",
            false,
            true
        ),
        new Interview(
            "Finding Meaningful Work – What Makes a Successful Engineer",
            "An interview focused on finding meaningful work as a successful engineer",
            "21 mins",
            FindingMeaningfulWorkThumbnail,
            "finding-meaningful-work",
            "finding_meaningful_work_&_what_makes_a_successful_engineer.mp4",
            "FindingMeaningfulWork",
            false,
            true
        )
    ],
    MOCK_INTERVIEWS: [
        new Interview(
            "Mock Interview (Non Technical): Entry Level Position",
            "A mock interview for a non-technical entry-level position",
            "13 mins",
            MockInterviewEntryLevelThumbnail,
            "mock-interview-entry-level",
            "clyde,_big_tech_company_-_entry_level_mock_interview.mp4",
            "MockInterviewEntryLevel",
            true,
            false
        ),
        new Interview(
            "Mock Interview (Non Technical): Mid Level Position",
            "A mock interview for a non-technical mid-level position",
            "11 mins",
            MockInterviewMidLevelThumbnail,
            "mock-interview-mid-level",
            "clyde,_big_tech_company_-_mid_level_mock_interview.mp4",
            "MockInterviewMidLevel",
            true,
            false
        ),
        new Interview(
            "Mock Interview: Coming From an Unrelated Field",
            "A mock interview for someone coming from an unrelated field",
            "7 mins",
            MockInterviewUnrelatedFieldThumbnail,
            "mock-interview-unrelated-field",
            "mock_interview__entering_from_a_different_field.mp4",
            "MockInterviewUnrelatedField",
            true,
            false
        )
    ]
}