import React, {useEffect, useState} from 'react';
import "../css/GenericPage.css"
import '../css/LandingPage.css';
import {useNavigate} from "react-router-dom";
import {DisplayBars} from "../components/DisplayBars";
import {TestimonialsModule} from "../components/TestimonialsModule";
import VideoPlayer from "../components/VideoPlayer";
import {userEmailEntered} from "../util/APIHelper";
import {ICONS} from "../util/IconHelper";
import KAZ_AVATAR from "../images/testimonials/Shane-K-Avatar.webp";
import DOM_AVATAR from "../images/testimonials/Dominik-Jasarevic-Avatar.webp";
import RYAN_AVATAR from "../images/testimonials/RyanTAvatar.webp";

export const LandingPage: React.FC<any> = ({ isAuthenticated, userProgressData, setShowConfirmationOverlay, setUserProgressData }) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isAuthenticated) {
            // let uid = localStorage.getItem("uid");
            //
            // getInitialLandingPage(uid || "")
            //     .then(res => res.json())
            //     .then(data => {
            //         if (data.uid && !uid) {
            //             localStorage.setItem("uid", data.uid);
            //         }
            //
            //         // If you want to update title/video on state:
            //         // setLandingPageData({ title: data.title, videoUrl: data.videoUrl });
            //     })
            //     .catch(err => {
            //         console.error("Failed to fetch landing page data", err);
            //     });
            return;
        }
        
        // Always redirect authenticated users
        if (userProgressData && userProgressData.hasOnboarded) {
            navigate("/dashboard");
        } else {
            navigate("/roadmap-builder");
        }
    }, [isAuthenticated, userProgressData, navigate]);

    // Render landing page content only for non-authenticated users
    if (isAuthenticated) {
        // Return empty div during redirect - it will not be visible
        return <div></div>;
    }

    return (
        <div className="page-container">
            <div className="hero-main-container">
                <DisplayBars position={'top'}/>
                <DisplayBars position={'bottom'}/>
                <div className={'hero-text-video-container'}>
                    <div className={"hero-video-container"}>
                        <VideoPlayer fileUrl={"landingPage/OverviewVideo1.mp4"} isPremium={true} isGenericPoster={true}/>
                    </div>
                    <div className="hero-text-container">
                        <h1>Helping You Build Your<br/>Coding Career</h1>
                        {!isAuthenticated && <EmailSubscribeInput />}
                        <TestimonialsModule/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const EmailSubscribeInput: React.FC = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleJoinClick = () => {
        if (!email.trim()) {
            setError("Please enter a valid email");
            return;
        }
        setError('');
        userEmailEntered(email);
        navigate(`/register?email=${encodeURIComponent(email)}`);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleJoinClick();
        }
    };

    return (
        <div className="email-subscribe-input">
            <div className="email-subscribe-input-container">
                <input
                    className={`email-subscribe-input-text ${error ? 'error' : ''}`}
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}  // Listen for Enter key
                />
                <button className="email-subscribe-input-button" onClick={handleJoinClick}>
                    Join for Free
                </button>
            </div>
            <div className="email-sign-up-under-form-container">
                <p className="email-sign-up-page-privacy-text">We’ll never share your info with anyone.</p>
            </div>
        </div>
    );
};

export const HeroReviews = () => {
    return (
        <div className="hero-review-container">
            {Array(5).fill(null).map((_, i) => (
                <ICONS.RATING_STAR key={i} className="hero-testimonial-star-icon"/>
            ))}
            <img id="hero-testimonial-1" src={KAZ_AVATAR} alt="Shane K testimonial avatar"/>
            <img id="hero-testimonial-2" src={DOM_AVATAR} alt="Dominik Jasarevic testimonial avatar"/>
            <img id="hero-testimonial-3" src={RYAN_AVATAR} alt="Ryan T testimonial avatar"/>
        </div>
    );
};