import React from "react";
import {OnboardingStep} from "../../constants/OnboardingConfig";

export const OnboardingProgressBar: React.FC<{currentIndex: number, onboardingFlow: OnboardingStep[]}> = ({currentIndex, onboardingFlow}) => {
    const progressTotal = onboardingFlow.filter(step => step.showProgressBar).length + 1;
    const progress = ((currentIndex + 1) / progressTotal) * 100
    return (
        <div className="progress-bar-wrapper">
            <div className="progress-bar" style={{width: `${progress}%`}}/>
        </div>
    )
}
