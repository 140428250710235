export const NOT_LANDING_INTERVIEWS_CONTENT_MARKUP: string = `# Why You're Not Landing Interviews

You ask a group of programmers, “What’s the worst part of job searching?”. What do you think they’ll say?

- “Bombing a coding interview”
- “Getting passed up for another applicant”
- “Receiving an offer and it's a lowball”

Now, I won’t lie. I’ve been there, and these all majorly suck. But there’s another scenario that trumps them all:

> “Apply for hundreds of jobs and hear nothing back.”

All those hours spent searching and hardly any responses back. Talk about feeling defeated. And worst of all, it's **incredibly** common.

But luckily, one simple change can have responses coming in.

## Why Does Nobody Respond?

Alright, first things first: why the hell is nobody responding to you?

Is it because you aren’t qualified? Is your resume just getting lost in massive piles of applicants? Or maybe hiring managers are just jerks who don’t value your time.

Well… it’s often none of these.

I’ve found one of the biggest reasons for people not hearing back is that their resume isn’t easy to read.

*(I know, I know. You’re probably thinking how simplistic and stupid that sounds. But just bear with me a second.)*

## The 7 Second Test

Before I dive into my reasoning, let’s do a quick test.

1. First, take out your resume.
   
   *(And yes, you should really take it out right now and do this.)*

2. Pretend you’ve never seen your resume before. You don’t know your experience, your education, etc. You have no idea who you are.

3. Set a timer on your phone for **7 seconds**. When you hit go, start reading your resume with “fresh eyes”.

4. Genuinely see how much you can learn about yourself in those 7 seconds. 
   - What kind of experience do you have?
   - What can you offer?

5. Do this now and then come back.

> *(Really! It’s worth it, I promise.)*

## The Results

How’d you do?

If your resume is like most people’s, you probably gathered your name and that your objective is *“I’d like a job”*. Not exactly the best description of you, huh?

Why does this little “7 Second Test” matter?

Because according to sources like [Indeed](https://www.indeed.com/career-advice/resumes-cover-letters/how-long-do-employers-look-at-resumes) and [Business Insider](https://www.businessinsider.com/heres-what-recruiters-look-at-during-the-6-seconds-they-spend-on-your-resume-2012-4), **that’s how long hiring managers spend looking at your resume before deciding to go forward or not.**

You’ve got **7 seconds** before they decide to keep learning more or to toss you aside.

While that might seem a bit harsh, the recruiter isn’t brushing you aside out of spite. They often have so many applicants that it’s not reasonable to give each one a thorough look.

That said, how do we make sure you pass the initial **7 seconds**?

---

## Tips for Improving Your Resume

### **1. Order your resume sections (and bullets in the sections) by relevancy to the job.**
- Suppose you didn’t go to college but have released several iOS apps. You would have a **"Personal Projects"** section way higher than your **"Education"** section.
- Let’s say you just graduated with a CS degree, but have no relevant work experience. You put your **"Education"** section above your **"Work Experience"** of delivering for Papa John's.

### **2. Be concise. Like *really* concise.**
- If your resume looks like an essay, it’s not getting read (yes, even if you did it in MLA format).
- Each item on your resume (a job, a school, etc) should have around **5 bullets max** that briefly describe it.

### **3. Don’t waste resume space on generic things.**
- Don’t put an **"Objective"** section (*I mean, we all know your objective is to get a job*).
- Don’t waste space describing yourself as *“hardworking, a quick learner, etc.”* It might be true, but if anyone can put it on their resume, it doesn’t hold much weight.

### **4. Keep it one page.**
- If you’re a newer dev and feel it's hard to keep things one page, **you’re not being nearly concise enough.**
- Once you have ~10 years of experience (or worked at ~5 or so jobs), you can decide on adding a second page.

### **5. Unfortunately, Buzzwords matter.**
- Every tech role has a list of **technologies** in the job posting. Make sure your resume **mentions any relevant ones** you have experience with.
- A **"Skills"** section at the bottom can be a great place for developers to list what they’ve worked with. That way, you don’t bloat the rest of your resume with **buzzwords**.

---

## Conclusion

Now, I can’t solve everyone’s resume in a short article. There’s an endless number of reasons why someone’s resume might not be working.

That said, I certainly think the general tips above can help you. After all, if your resume isn’t **easily readable**, none of your other potential issues even matter.

Now, if you want a **step-by-step guide to making a great resume**, I happen to have a course that covers just that! *(How convenient, I know 😉).*

But even if you don’t take the course, I really hope this article helps you improve your resume and earn **more responses from recruiters**.

And finally, **best of luck on your job search!** 🚀
`;