// ResumeReviewerPage.tsx
import React, {useState} from "react";
import "../css/ToolPage.css"
import {useParams} from "react-router-dom";
import {INTERVIEW_QUESTIONS, RESUME_REVIEWER, TOOL_TYPES_CONFIG} from "../constants/ToolTypesConfig";
import {ToolType} from "../models/ToolType";
import {FileUploader} from "../components/FileUploader";
import {analyzeUserResume, generateInterviewQuestions} from "../util/APIHelper";
import {ResumeUploadedModule} from "../components/ResumeUploadedModule";
import {ProgressBar} from "../components/ProgressBar";
import { IoRefreshCircleSharp } from "react-icons/io5";

function getToolPage(url: string): any {
    return TOOL_TYPES_CONFIG.find((toolPage) => toolPage.navUrl === url) || null;
}

export const ResumeReviewerPage: React.FC<any> = ({isPremium, setShowPremiumOverlay, isAuthenticated}) => {
    const {type} = useParams();
    const toolType = getToolPage(type || "");
    if (!toolType) {
        return <></>;
    }

    return (
        <div className="tool-page-container">
            <div className="tool-page-content">
                <div className="tool-text-container">
                    <h4>AI Tools</h4>
                    <h1>{toolType.name}</h1>
                    <h2>{toolType.description}</h2>
                </div>
                {toolType.type === RESUME_REVIEWER && <ResumeReviewerTool toolType={toolType} isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay} isAuthenticated={isAuthenticated}/>}
                {toolType.type === INTERVIEW_QUESTIONS && <ResumeReviewerTool toolType={toolType} isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay} isAuthenticated={isAuthenticated}/>}
            </div>
        </div>
    );
};

const ResumeReviewerTool: React.FC<{ toolType: ToolType, isPremium: boolean, setShowPremiumOverlay: any, isAuthenticated: boolean }> = ({ toolType, isPremium, setShowPremiumOverlay, isAuthenticated }) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // Store file temporarily
    const [jobDescription, setJobDescription] = useState<string>(""); // Store job description
    const [analyzingState, setAnalyzingState] = useState<"idle" | "analyzing" | "results">("idle");
    const [analysisResult, setAnalysisResult] = useState<any>(null);

    const handleFileUpload = (file: File) => {
        setUploadedFile(file);
        setAnalysisResult(null);
        setAnalyzingState("idle");
    };

    const handleJobDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJobDescription(event.target.value);
    };

    const handleAnalyzeResume = async () => {
        if (!uploadedFile) return;

        try {
            setAnalyzingState("analyzing");
            if (toolType.type === RESUME_REVIEWER) {
                const result = await analyzeUserResume(uploadedFile);
                setAnalysisResult(result);
                setAnalyzingState("results");
            } else if (toolType.type === INTERVIEW_QUESTIONS) {
                const result = await generateInterviewQuestions(uploadedFile, jobDescription);
                setAnalysisResult(result);
                setAnalyzingState("results");
            }
        } catch (err) {
            console.error("Error analyzing resume:", err);
            setAnalyzingState("idle");
        }
    };

    const onRedoClicked = () => {
        setAnalysisResult(null);
        setUploadedFile(null)
        setAnalyzingState("idle");
    }

    return (
        <div className={`resume-reviewer-container ${analyzingState === "results" ? "results" : ""}`}>
            {analyzingState === "idle" && (
                <div className="resume-reviewer-not-analyzed-container">
                    <div className="resume-reviewer-title-container">
                        <h2>{toolType.toolHeader}</h2>
                        <toolType.toolHeaderIcon/>
                    </div>
                    <div className={'tools-file-uploader-container'}>
                        <h4 className={'tools-needed-info-header'}>Resume</h4>
                        {!uploadedFile ? (
                            <FileUploader toolType={toolType} onFileUpload={handleFileUpload} isPremium={isPremium}
                                          setShowPremiumOverlay={setShowPremiumOverlay} isAuthenticated={isAuthenticated} />
                        ) : (
                            <div className="resume-reviewer-uploaded-container">
                                <ResumeUploadedModule file={uploadedFile}/>
                            </div>
                        )}
                    </div>

                    {toolType.type === INTERVIEW_QUESTIONS && <div className={'tools-job-description-input'}>
                        <h4 className={'tools-needed-info-header'}>Job Description</h4>
                        <textarea
                            id="tools-page-job-description-input"
                            name="jobDescription"
                            value={jobDescription}
                            onChange={handleJobDescriptionChange}
                            placeholder="Copy/paste the job description text here..."
                        />
                    </div>}
                    <button className="analyze-resume-button" onClick={handleAnalyzeResume} disabled={!uploadedFile}>
                        {toolType.beginCtaText}
                    </button>
                </div>
            )}
            {analyzingState === "analyzing" && (
                <div className="resume-analyzing-container">
                    <h2>{toolType.inProgressText}</h2>
                    <ProgressBar length={15}/>
                </div>
            )}
            {analyzingState === "results" && analysisResult && (
                <div className={'tool-results-container'}>
                    {toolType.type === RESUME_REVIEWER && <ResumeReviewResults onRedoClicked={onRedoClicked} analysisResult={analysisResult} />}
                    {toolType.type === INTERVIEW_QUESTIONS && <InteviewQuestionsResults onRedoClicked={onRedoClicked} analysisResult={analysisResult} />}
                </div>
            )}
        </div>
    );
};

const ResumeReviewResults: React.FC<any> = ({onRedoClicked, analysisResult}) => {
    return (<div className="specific-tool-result">
        <IoRefreshCircleSharp onClick={onRedoClicked} id={'resume-analysis-result-redo-icon'}/>
        <h3>Analysis Results:</h3>
        <p><strong className="analysis-section-header">Summary:</strong> {analysisResult.summary}</p>
        <p className="analysis-section-header"><strong>Pros:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.pros.map((pro: string, index: number) => (
                <li key={index}>{pro}</li>
            ))}
        </ul>
        <p className="analysis-section-header"><strong>Cons:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.cons.map((con: string, index: number) => (
                <li key={index}>{con}</li>
            ))}
        </ul>
        <p className="analysis-section-header"><strong>Suggestions:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.suggestions.map((suggestion: string, index: number) => (
                <li key={index}>{suggestion}</li>
            ))}
        </ul>
        <p className="analysis-section-header"><strong>Grammar Mistakes:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.grammar_mistakes.map((mistake: string, index: number) => (
                <li key={index}>{mistake}</li>
            ))}
        </ul>
    </div>)
}

const InteviewQuestionsResults: React.FC<any> = ({onRedoClicked, analysisResult}) => {
    return (<div className="specific-tool-result">
        <IoRefreshCircleSharp onClick={onRedoClicked} id={'resume-analysis-result-redo-icon'}/>
        <h3>Interview Questions:</h3>
        <p className="analysis-section-header"><strong>Technical Questions:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.technicalQuestions.map((pro: string, index: number) => (
                <li key={index}>{pro}</li>
            ))}
        </ul>
        <p className="analysis-section-header"><strong>Non Technical Questions:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.nonTechnicalQuestions.map((con: string, index: number) => (
                <li key={index}>{con}</li>
            ))}
        </ul>
        <p className="analysis-section-header"><strong>Questions for Interviewer:</strong></p>
        <ul className="analysis-section-list">
            {analysisResult.questionsForInterviewer.map((con: string, index: number) => (
                <li key={index}>{con}</li>
            ))}
        </ul>
    </div>)
}