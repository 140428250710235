import React, {useEffect, useState} from 'react';
import '../css/LearningPathContent.css'
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {trackEvent} from "../util/TrackingHelper";
import {ALL_CONTENT, ENTIRE_LEARNING_PATH} from "../constants/LearningPathsConfig";
import {FaVideo, FaPen, FaArrowDown} from "react-icons/fa";
import AIStar from "../images/ai-star.svg"
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import {IoIosDocument} from "react-icons/io";
import { IoClose, IoAddCircle } from "react-icons/io5";
import { LuSparkle } from "react-icons/lu";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {getUserProgress, hasUserCompleted, isInUsersRoadmap} from "../util/UserProgressHelper";
import {updateItemCompleted, updateRoadmap} from "../util/APIHelper";

export const AllContent: React.FC<any> = ({isAuthenticated, userProgressData, setShowConfirmationOverlay, setUserProgressData, setShowPremiumOverlay, isLandingPage}) =>  {
    const [courseHeader, setCourseHeader] = useState<string>(ALL_CONTENT.allCourses[0].header);
    const [interviewHeader, setInterviewHeader] = useState<string>(ALL_CONTENT.allInterviews[0].header);
    const [resourceHeader, setResourceHeader] = useState<string>(ALL_CONTENT.allResources[0].header);
    return (
        <div className={'all-content-container'}>
            {!isLandingPage && <LearningPath userProgressData={userProgressData} setShowConfirmationOverlay={setShowConfirmationOverlay} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay}/>}
            {isLandingPage && <div className={'all-content-content-listed'}>
                <ContentType selectedHeader={courseHeader} setSelectedHeader={setCourseHeader} header={"Courses"} contentType={"courses"} allContent={ALL_CONTENT.allCourses} isAuthenticated={isAuthenticated} setShowConfirmationOverlay={setShowConfirmationOverlay} userProgressData={userProgressData} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay}/>
                <ContentType selectedHeader={ALL_CONTENT.allAITools[0].header} header={ALL_CONTENT.allAITools[0].header} contentType={"tools"} allContent={ALL_CONTENT.allAITools} isAuthenticated={isAuthenticated} setShowConfirmationOverlay={setShowConfirmationOverlay} userProgressData={userProgressData} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay}/>
                <ContentType selectedHeader={interviewHeader} setSelectedHeader={setInterviewHeader} header={"Interviews"} contentType={"interviews"} allContent={ALL_CONTENT.allInterviews} isAuthenticated={isAuthenticated} setShowConfirmationOverlay={setShowConfirmationOverlay} userProgressData={userProgressData} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay}/>
                <ContentType selectedHeader={resourceHeader} setSelectedHeader={setResourceHeader} header={"Resources"} contentType={"resources"} allContent={ALL_CONTENT.allResources} isAuthenticated={isAuthenticated} setShowConfirmationOverlay={setShowConfirmationOverlay} userProgressData={userProgressData} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay}/>
            </div>}
        </div>
    )
}

interface ContentTypeProps {
    selectedHeader?: string;
    setSelectedHeader?: (header: string) => void;
    header: string;
    contentType: string;
    allContent: any [];
    isAuthenticated: boolean;
    setShowConfirmationOverlay: any;
    userProgressData: any;
    setUserProgressData: any;
    setShowPremiumOverlay: any;
}

export const ContentType: React.FC<ContentTypeProps> = ({selectedHeader, setSelectedHeader, header, contentType, allContent, isAuthenticated, setShowConfirmationOverlay, userProgressData, setUserProgressData, setShowPremiumOverlay}) =>  {
    return (
        <div className="learning-path-content-container">
            <div className={'learning-path-overall-header-container'}>
                <h3 className="learning-path-overall-header">{header}</h3>
                {contentType === "tools" && <img src={AIStar} alt={'AI Icon'}/>}
            </div>
            {setSelectedHeader &&
                <div className="learning-path-header-list">
                    {allContent.map((contentList, index) => (
                        <h3
                            key={index}
                            className={`learning-path-header${index === 0 ? ' first' : ''}${selectedHeader === contentList.header ? ' selected-header' : ''}`}
                            onClick={() => setSelectedHeader(contentList.header)}
                        >
                            {contentList.header}
                        </h3>
                    ))}
                </div>
            }
            <div className={'learning-path-container-wrapper'}>
                <div className={`learning-path-container ${contentType}`}>
                    {allContent.map((contentList: any, index) => (
                        <>
                            {selectedHeader === contentList.header && (
                                <div className={`learning-path-list-content ${contentType}`}>
                                    {contentList.content.map((content: any, index: number) => (
                                        <div className={'learning-grid-item-container'}>
                                            <LearningGridItem key={index} item={content} type={`${contentType}`}
                                                              page={`${contentList.pageTracking}`}
                                                              typeTracking={`${contentType}`}
                                                              isAuthenticated={isAuthenticated}
                                                              setShowConfirmationOverlay={setShowConfirmationOverlay}
                                                              userProgressData={userProgressData}
                                                              setUserProgressData={setUserProgressData}
                                                              setShowPremiumOverlay={setShowPremiumOverlay}/>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}

const LearningGridItem: React.FC<any> = ({item, type, page, typeTracking, isAuthenticated, setShowConfirmationOverlay, userProgressData, setUserProgressData, setShowPremiumOverlay}) => {
    const navigate = useNavigate();
    let navigateUrl = `/${type}/${item.navUrl}`;
    const handleClick = () => {
        if (userProgressData && !userProgressData.isPremium) {
            setShowPremiumOverlay(true)
        } else {
            navigate(navigateUrl);
            trackEvent({event: `${typeTracking}_${item.trackingId}`, page: page})
        }
    }
    const handleAddToRoadmap = () => {
        if (!userProgressData.isPremium) {
            setShowPremiumOverlay(true)
        } else {
            setShowConfirmationOverlay("Add to Roadmap", "Would you like to add this item to your roadmap?", "Confirm", () => {
                updateRoadmap(type, item.trackingId, userProgressData.userId, true).then(response => {
                    setUserProgressData(response?.data)
                })
            })
        }
    }
    return (
        <div className={`grid-item-container ${type} ${item.navUrl}`} onClick={handleClick}>
            <img src={item.thumbnail} alt={`${item.name} icon`}/>
            <div className={"grid-item-info-container"}>
                <h4 className={"learning-grid-item-header"}>{item.name}</h4>
                {(type === "courses" || type === "articles" || type === "tools") && (
                    <p className={"learning-grid-item-description"}>{item.description}</p>
                )}
                {type !== "tools"  && (
                    <div className={"learning-grid-item-icons-list-container"}>
                        {(type === "interviews" || type === "courses") &&
                            <GridItemIconInfo icon={ICONS.CLOCK} text={item.time}/>}
                        {type === "courses" &&
                            <GridItemIconInfo icon={ICONS.LESSONS} text={`${item.numberOfLessons} Lessons`}/>}
                        {type === "resources" && <GridItemIconInfo icon={ICONS.PDF} text="Download PDF"/>}
                        {type === "articles" && <GridItemIconInfo icon={ICONS.CALENDAR} text={item.date}/>}
                    </div>
                )}
            </div>
            {isAuthenticated && !isInUsersRoadmap(userProgressData, item, type) && <div className={'add-to-roadmap-icon-container'} onClick={(e) => e.stopPropagation()}>
                <IoAddCircle id={'add-to-roadmap-icon'} onClick={() => handleAddToRoadmap()} />
            </div>}

        </div>
    )
}

export const GridItemIconInfo: React.FC<any> = ({icon: Icon, text}) => {
    return (
        <div className={"learning-grid-item-icon-container"}>
            <Icon/>
            <h5>{text}</h5>
        </div>
    )
}

export const LearningPath: React.FC<any> = ({userProgressData, setShowConfirmationOverlay, setUserProgressData, setShowPremiumOverlay}) => {
    const [selectedHeader, setSelectedHeader] = useState<number>(0);
    const percentage = getUserProgress(userProgressData);
    let isInitialItem = true
    return (
        <div className='learning-path-container'>
            <div className={'learning-path-start-container'}>
                <div className={'learning-path-progress-container'}>
                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                </div>
                <div className={'learning-path-header-container'}>
                    <h4>Personal Roadmap</h4>
                    <h5>Your Path to Landing Your Coding Job</h5>
                </div>
            </div>
            <div className="learning-path-header-list">
            {ENTIRE_LEARNING_PATH.map((contentList, index) => (
                    <h3 key={index}
                        className={`learning-path-header${index === 0 ? ' first' : ''}${index === selectedHeader ? ' selected-header' : ''}`}
                        onClick={() => setSelectedHeader(index)}>
                        {contentList.header}
                    </h3>
                ))}
            </div>
            <div className={'roadmap-section-container'}>
                {ENTIRE_LEARNING_PATH[selectedHeader].content.map((content: any, index) => {
                    const isInRoadmap = isInUsersRoadmap(userProgressData, content.content, content.type);
                    return (
                        isInRoadmap && (
                            <React.Fragment key={index}>
                                {!isInitialItem && (
                                    <FaArrowDown id={'roadmap-section-arrow'}/>
                                )}
                                {isInitialItem = false}
                                <RoadMapItem
                                    item={content.content}
                                    type={content.type}
                                    isAuthenticated={true}
                                    index={index}
                                    userProgressData={userProgressData}
                                    setShowConfirmationOverlay={setShowConfirmationOverlay}
                                    setUserProgressData={setUserProgressData}
                                    setShowPremiumOverlay={setShowPremiumOverlay}
                                />

                            </React.Fragment>
                        )
                    );
                })}
            </div>
        </div>
    )
}

const RoadMapItem: React.FC<any> = ({
                                        item,
                                        type,
                                        isAuthenticated,
                                        index,
                                        userProgressData,
                                        showArrow,
                                        setShowConfirmationOverlay,
                                        setUserProgressData,
                                        setShowPremiumOverlay
                                    }) => {
    const onCompletedItemClicked = () => {
        if (!userProgressData.isPremium) {
            setShowPremiumOverlay(true)
        } else {
            updateItemCompleted(type, item.trackingId, userProgressData.userId, !hasUserCompleted(userProgressData, item, type)).then(response => {
                setUserProgressData(response?.data)
            })
        }
    }

    const onRemoveFromRoadmap = () => {
        if (!userProgressData.isPremium) {
            setShowPremiumOverlay(true)
        } else {
            setShowConfirmationOverlay("Remove Step?", "Are you sure you want to remove this step from your roadmap", "Confirm", () => {
                updateRoadmap(type, item.trackingId, userProgressData.userId, false).then(response => {
                    setUserProgressData(response?.data)
                })
            })
        }

    }
    return (
        <div className={`roadmap-element ${type}`}>
            <RoadMapItemContent item={item} type={type} userProgressData={userProgressData}
                                onRemoveFromRoadmap={onRemoveFromRoadmap} onCompletedItemClicked={onCompletedItemClicked} setShowPremiumOverlay={setShowPremiumOverlay}/>
        </div>
    )
}

export const RoadMapItemContent: React.FC<any> = ({item, type, userProgressData, onRemoveFromRoadmap, onCompletedItemClicked, setShowPremiumOverlay}) => {
    console.log(item)
    const navigate = useNavigate();
    let navigateUrl = `/${type}/${item.navUrl}`;
    const handleClick = () => {
        if (!userProgressData.isPremium) {
            setShowPremiumOverlay(true)
        } else {
            if (type !== "actions") {
                navigate(navigateUrl);
            }
        }
    }
    const onCheckClicked = () => {
        setIsCompleted(!isCompleted)
        onCompletedItemClicked()
    }
    const [isCompleted, setIsCompleted] = useState(hasUserCompleted(userProgressData, item, type));

    useEffect(() => {
        setIsCompleted(hasUserCompleted(userProgressData, item, type));
    }, [userProgressData, item, type]);

    return (
        <div className={`roadmap-item-content-container  ${type} ${isCompleted ? 'completed' : ''}`} onClick={handleClick}>
            <div className={'roadmap-item-image-container'}>
                <RoadMapIcon type={type}/>
                {type !== 'actions' && <img className={`roadmap-image ${type}`} src={item.thumbnail} alt={`${item.name} icon`}/>}
            </div>
            <div className={`roadmap-item-info-container`}>
                <h4>{item.name}</h4>
                {type === "actions" && <div id={'action-items-disclosure'}>Note: Action items are completed off-site</div>}
                {type !== "tools" && type !== "actions" && (
                    <div className={"roadmap-item-icons-list-container"}>
                        {(type === "interviews" || type === "courses") &&
                            <GridItemIconInfo icon={ICONS.CLOCK} text={item.time}/>}
                        {type === "courses" &&
                            <GridItemIconInfo icon={ICONS.LESSONS} text={`${item.numberOfLessons} Lessons`}/>}
                        {type === "resources" && <GridItemIconInfo icon={ICONS.PDF} text="Download PDF"/>}
                        {type === "articles" && <GridItemIconInfo icon={ICONS.CALENDAR} text={item.date}/>}
                    </div>
                )}
                <label className={'roadmap-item-checkbox'} onClick={(e) => e.stopPropagation()}>Completed<input type="checkbox" checked={isCompleted} onClick={() => onCheckClicked()}/></label>
                <div className={'roadmap-section-remove-container'} onClick={(e) => e.stopPropagation()}><IoClose className={'roadmap-section-remove'} onClick={() => onRemoveFromRoadmap()} /></div>
            </div>
        </div>
    )}

export const RoadMapIcon: React.FC<{ type: string }> = ({type}) => {
    return (
        <div className={'roadmap-item-icon-container onboarding'}>
            <div className={`roadmap-item-icon ${type}`}>
                {type === "courses" && <LiaChalkboardTeacherSolid/>}
                {type === "interviews" && <FaVideo/>}
                {type === "resources" && <IoIosDocument/>}
                {type === "tools" && <LuSparkle/>}
                {type === "actions" && <FaPen/>}
            </div>
            <div className={`roadmap-icon-name ${type}`}>{getTypeDisplayName(type)}</div>
        </div>
    )
}

export const getTypeDisplayName = (type: string) => {
    switch (type) {
        case "courses":
            return "Course"
        case "interviews":
            return "Interview"
        case "resources":
            return "Resource"
        case "tools":
            return "AI Tool"
        case "actions":
            return "Action Item"
    }
    return ""
}