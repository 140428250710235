import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LandingPage } from "./pages/LandingPage";
import "./css/Global.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LearningContentPage } from "./pages/LearningContentPage";
import { LessonPage } from "./pages/LessonPage";
import { AccountPage } from "./pages/AccountPage";
import initializeStripe from "./util/InitializeStripe";
import ScrollToTop from "./util/ScrollToTop";
import { PremiumOverlay } from "./components/PremiumOverlay";
import { isUserPremium } from "./util/APIHelper";
import { EmailSignUpPage } from "./pages/EmailSignUpPage";
import { RegisterPage } from "./pages/RegisterPage";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import {ResumeReviewerPage} from "./pages/ResumeReviewerPage";
import {OnboardingPage} from "./pages/OnboardingPage";
import {ConfirmationOverlay} from "./components/ConfirmationOverlay";
import {DashboardPage} from "./pages/Dashboard";
import {WelcomePage} from "./pages/WelcomePage";
import {AllBlogsPage, BlogPage} from "./pages/BlogPage";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
    const [user, setUser] = useState(null);
    const [userProgressData, setUserProgressData] = useState(null);
    const [showPremiumOverlay, setShowPremiumOverlay] = useState(false);
    const [showConfirmationOverlay, setShowConfirmationOverlay] = useState(false);
    const [overlayConfig, setOverlayConfig] = useState({
        header: '',
        text: '',
        approveText: '',
        onConfirm: () => {}
    });

    const showConfirmation = (header, text, confirmText, onConfirm) => {
        setOverlayConfig({ header, text, approveText: confirmText, onConfirm });
        setShowConfirmationOverlay(true);
    };

    useEffect(() => {
        initializeStripe().then(() => {
            console.log('Stripe initialized');
        }).catch((error) => {
            console.error('Error initializing Stripe:', error);
        });

        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                setIsPremium(false);
                setUser(null);
                setShowPremiumOverlay(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const checkUserStatus = async () => {
        console.log("Checking User Status...");
        if (user) {
            setIsGettingUserInfo(true);
            const userData = await isUserPremium(user.uid);
            setUserProgressData(userData)
            setIsPremium(userData.isPremium);
            setIsGettingUserInfo(false);
        }
    };

    useEffect(() => {
        checkUserStatus();
    }, [user]);

    return (
        <Router>
            <ScrollToTop />
            <Analytics />
            <SpeedInsights />
            <div className="app-container">
                <ConditionalLayout isAuthenticated={isAuthenticated}>
                    {isGettingUserInfo ? (<p></p>) : (
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <LandingPage
                                        isAuthenticated={isAuthenticated}
                                        userProgressData={userProgressData}
                                        setShowConfirmationOverlay={showConfirmation}
                                        setUserProgressData={setUserProgressData}
                                    />
                                }
                            />
                            <Route
                                path="/dashboard"
                                element={
                                    <DashboardPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                        userProgressData={userProgressData}
                                        setShowConfirmationOverlay={showConfirmation}
                                        setUserProgressData={setUserProgressData}
                                    />
                                }
                            />
                            <Route path="/welcome" element={<WelcomePage />} />
                            <Route path="/roadmap-builder" element={<OnboardingPage userProgressData={userProgressData} user={user} setUserProgressData={setUserProgressData}/>} />
                            <Route path="/register" element={<RegisterPage />} />
                            <Route path="/login" element={<RegisterPage isLogin={true}/>} />
                            <Route path="/account" element={<AccountPage />} />
                            <Route path="/:contentType/:contentName"
                                element={
                                    <LearningContentPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                    />
                                }
                            />
                            <Route
                                path="/courses/:course/:sectionIdParam/:lessonIdParam"
                                element={
                                    <LessonPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                    />
                                }
                            />
                            <Route path="/email-signup/:type" element={<EmailSignUpPage />} />
                            <Route path="/tools/:type" element={
                                <ResumeReviewerPage isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay} isAuthenticated={isAuthenticated}/>
                            } />
                            <Route path="/blog/:blogId" element={<BlogPage />} />
                            <Route path="/blog/" element={<AllBlogsPage />} />
                        </Routes>
                    )}
                </ConditionalLayout>
                {showPremiumOverlay && <PremiumOverlay user={user} setShowPremiumOverlay={setShowPremiumOverlay} />}
                {showConfirmationOverlay && <ConfirmationOverlay setShowConfirmationOverlay={setShowConfirmationOverlay} overlayConfig={overlayConfig} />}
            </div>
        </Router>
    );
}

const ConditionalLayout = ({children, isAuthenticated}) => {
    const location = useLocation();
    const isRegisterPage = location.pathname === "/register" || location.pathname === "/login";
    const isOnboarding = location.pathname === "/roadmap-builder";

    return (
        <>
            {!isRegisterPage && (<Header isAuthenticated={isAuthenticated}/>)}
            {children}
            {!isRegisterPage && !isOnboarding && <Footer />}
        </>
    );
};

export default App;