import React from 'react';
import "../css/GenericPage.css"
import {ICONS} from "../util/IconHelper";

export interface ConfirmationConfig {
    header: string;
    text: string;
    approveText: string;
    onConfirm: () => void;
}

export const ConfirmationOverlay: React.FC<any> = ({setShowConfirmationOverlay, overlayConfig}) => {
    const handleConfirmClick = () => {
        overlayConfig.onConfirm();
        setShowConfirmationOverlay(false);
    }
    return (
        <div className={'overlay-container'} onClick={() => setShowConfirmationOverlay(false)}>
            <div className={`overlay-content`} onClick={(e) => e.stopPropagation()}>
                <h5 className={'overlay-content-header'}>{overlayConfig.header}</h5>
                <h6 className={'overlay-content-text'}>{overlayConfig.text}</h6>
                <div className={'confirmation-modal-button-container'}>
                    <button onClick={() => setShowConfirmationOverlay(false)}>Cancel</button>
                    <button id={'confirmation-modal-button-confirm'} onClick={() => handleConfirmClick()}>{overlayConfig.approveText}</button>
                </div>
                <ICONS.CLOSE id={'confirmation-overlay-close-button'} onClick={() => setShowConfirmationOverlay(false)}/>
            </div>
        </div>
    );
};