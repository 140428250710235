import React from "react";
import "../css/Footer.css";
import {FOOTER_CONFIG} from "../constants/FooterConfig";
import LogoMedium from "../images/Logo_Medium.webp";
import {useNavigate} from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate()
    return (
        <div className="footers-module-container">
            <div className="footers-module-content">
                <img className={"footer-logo"} src={LogoMedium} alt={"Beyond Code Logo"}/>
                <div className={"contact-us-container"}>
                    <div className={'footer-link'} onClick={() => navigate('/blog')}>Blog</div>
                    <div>{FOOTER_CONFIG.contactUsContent}</div>
                </div>
                <div className={"legal-text-container"}>
                    <p>{FOOTER_CONFIG.allRights}</p>
                    <a href={FOOTER_CONFIG.privacyUrl}>{FOOTER_CONFIG.privacyText}</a>
                    <a href={FOOTER_CONFIG.termsUrl}>{FOOTER_CONFIG.termsText}</a>
                </div>
            </div>
        </div>
    );
};