export const COVER_LETTER_FOR_TECH_JOB_CONTENT_MARKUP: string = `# Cover Letters for Tech Jobs: Are They Worth It?

Let’s be honest. Job searching these days can be awful.

- You find a job to apply for.
- You upload your resume.
- You then manually enter all the information that is already on your resume.
- You submit (and probably never hear back).

And then on top of it, they all ask you for a personalized letter. **Seriously?**

---

## Are Cover Letters Actually Read?

One of my favorite features on **BeyondCode** is our Expert Advice.  
I surveyed people all across my network in tech, asking them their honest opinion on many different topics.

**Question: Are cover letters required?**  
**Answer:** No

**Question: Do you read cover letters?**  
**Answer:** Yes

According to a recent survey from **Resume Genius**, **83%** of hiring managers read most cover letters they receive.  
**83%!**

Alright, alright, fine – cover letters are usually read. But are they actually **helpful and worth the effort** to write?

---

## Standing Out As An Applicant

Let’s think about the application process.

For each job you apply to, you’re actively competing with every other applicant. *(Very revolutionary insight, I know.)*

But given that we’re constantly competing, we need ways to stand out whenever possible.  

Obviously, your **resume** is the main tool for distinguishing yourself before interviews. The only issue is that **resumes usually lack personality**.

The **cover letter**, on the other hand, gives us a brief chance to be **personable**.  
We can introduce **who we are, why we’re interested in the company, and the value we can bring them**.

On top of that, cover letters **also show that you care enough about the job** to go out of your way and submit one.  
If you were **equally tied with another candidate** based on resumes, a well-written letter might give you **the slight edge**.

---

## The Secret to Writing Quick Cover Letters

So as I’m sure you can guess, I strongly urge candidates to take the time to submit cover letters **(especially if you’re applying to a company you’re really interested in).**

Now, I know many of you are ready to scoff and tell me that the application process is **bad enough** without having to pretend you’re Mark Twain each time.  
**I agree with you.**

While I think you should submit cover letters, I **don’t** think you should write **new ones each time**.  
That’d be an insane ask of someone.

That said, I also don’t think a **completely reusable cover letter** does much for you.  
It’s pretty obvious from a hiring manager’s perspective if it’s just a **copy-paste letter**.

The secret is learning how to create a **mostly reusable** letter that you can **slightly tweak**.

> You’ll want to spend time creating a **base cover letter** that is ~80% reusable.  
> I usually do a **small paragraph introducing myself**, a **small paragraph talking about the company**, and a **conclusion sentence**.

Then, as you apply to different jobs, you can **add just a few small details** that show you’ve tailored the letter **to this specific company**.

---

## Real Example

To help demonstrate my point, I’ve taken two sentences from the actual **cover letter I used when applying to LinkedIn**.

In order to show how it’s reusable, I’ve highlighted parts in the following colors:

- **🟩 Green:** Shouldn’t Change
- **🟧 Yellow:** Might Change
- **🟥 Red:** Should Change

### **Example 1: My Intro**
> 🟩 I recently discovered your job opening for an Android Developer.  
> 🟩 I am currently a Senior Mobile Developer  
> 🟧 for a company that provides mobile development solutions for high-end clients such as  
> 🟥 [Client 1]  
> 🟩 and  
> 🟥 [Client 2].

Almost nothing here changes. The **only possible thing** would be if I apply for a job with a **different title**.

### **Example 2: Talking About the Company**
> 🟩 I noticed that your company has a mission to  
> 🟥 provide economic opportunity for all members of the global workforce.  
> 🟩 I find this to strongly align with my own passion for  
> 🟥 creating software that provides opportunity for people in need.

In this case, I simply **swap out the company’s specific values** into the first red block.  
Then I mention **why I align with those values** in the second red block.

> 🟧 I might need to update the parts in yellow *(like if they say "Company Values" instead of "Company Mission")*, but it's not too often.

All in all, this **process of tweaking my cover letter doesn’t take much extra time**.  
I can **look up some info on the company, swap out some words, and submit the cover letter in under 5 minutes**.

---

## Conclusion

While **cover letters aren’t required**, I believe **applicants should use every opportunity to stand out**.

Cover letters **provide a chance to show more personality** and interest in the company.  

While writing cover letters **from scratch each time would be insane**, a **mostly reusable** cover letter is a **quick and effective** way to stand out. 🚀
`;