import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import '../css/BlogPage.css';
import { Article } from "../models/Article";
import { ARTICLES_MARKUP } from "../constants/ArticlesConfig";

export const AllBlogsPage: React.FC = () => {
    return (
        <div className="page-container">
            <div className="all-blog-page-container">
                <h2>All Blog Posts</h2>
                <div className="all-blog-page-content">
                    {ARTICLES_MARKUP.map((article, index) => (
                        <BlogPreview key={index} article={article}/>
                    ))}
                </div>

            </div>
        </div>
    );
};

export const BlogPreview: React.FC<{ article: Article }> = ({ article }) => {
    const navigate = useNavigate();

    return (
        <div className="blog-preview" onClick={() => navigate(`/blog/${article.navUrl}`)}>
            <img src={article.thumbnail} alt={`${article.name} icon`}/>
            <div className={'blog-preview-text-container'}>
                <h2>{article.name}</h2>
                <p className="blog-snippet">
                    {article.content.split("\n").slice(1, 4).join(" ") + "..."}
                </p>
            </div>
        </div>
    );
};

function getBlogPage(url: string): Article | null {
    return ARTICLES_MARKUP.find((article) => article.navUrl === url) || null;
}

export const BlogPage: React.FC = () => {
    const { blogId } = useParams();
    const articleData = getBlogPage(blogId || "");

    if (!articleData) {
        return <p>Article not found</p>;
    }

    return (
        <div className="page-container">
            <div className={"blog-page-container"}>
                <img src={articleData.thumbnail} alt={`${articleData.name} icon`}/>
                <ReactMarkdown>{articleData.content}</ReactMarkdown>
            </div>
        </div>
    );
};