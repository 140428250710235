import React from "react";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import "../css/Onboarding.css";
import OnboardingMapImage from "../images/onboarding/onboarding-map.svg";
import {HeaderSubheaderComponent} from "../components/BCButton";
import {getTrialEndDate} from "../components/onboarding/TrialSection";

export const WelcomePage: React.FC = () => {
    const navigate = useNavigate();

    const onBegin = () => {
        navigate("/dashboard")
    }

    return (
        <div className="onboarding-flow-container">
            <div className={'flow-content'}>
                <div className="onboarding-content-container final-section">
                    <HeaderSubheaderComponent text={"Welcome to Beyond Code!"} subtext={`Your free trial ends ${getTrialEndDate()}`}/>
                    <img src={OnboardingMapImage} className="welcome-image" alt="Road Map"/>
                    <button className="flow-next-button welcome" onClick={() => onBegin()}>Begin!</button>
                </div>
            </div>
        </div>
    );
};