import AIImpactThumbnail from "../images/articles/ai-impact-on-developers.webp";
import NotLandingInterviewsThumbnail from "../images/articles/not-landing-interviews.webp";
import DoIReallyNeedACoverLetterThumbnail from "../images/articles/do-i-really-need-a-cover-letter.webp";
import {Article} from "../models/Article";
import {COVER_LETTER_FOR_TECH_JOB_CONTENT_MARKUP} from "./articles/CoverLetterForTechJob";
import {AIS_IMPACT_ON_DEVELOPERS_CONTENT_MARKUP} from "./articles/AIsImpactOnDevelopers";
import {NOT_LANDING_INTERVIEWS_CONTENT_MARKUP} from "./articles/NotLandingInterviewsTest";

export const ARTICLES_MARKUP = [
    new Article(
        "AI's Impact on Future Software Developers",
        "It's no secret: AI is currently changing our world. And with these changes, I hear one question over and over again.. \"Will AI replace software developers?\"",
        AIImpactThumbnail,
        "ais-impact-on-developers",
        AIS_IMPACT_ON_DEVELOPERS_CONTENT_MARKUP,
        "April 27, 2024",
        "AiImpactOnSoftwareDevelopers",
        false
    ),
    new Article(
        "Not Landing Interviews? See If Your Tech Resume Passes This Quick Test",
        "You ask a group of programmers, “What’s the worst part of job searching?”. What do you think they’ll say?",
        NotLandingInterviewsThumbnail,
        "not-landing-interviews-test",
        NOT_LANDING_INTERVIEWS_CONTENT_MARKUP,
        "June 5, 2024",
        "NotLandingInterviewsTest",
        false
    ),
    new Article(
        "Do I really need a cover letter for a tech job?",
        "Let’s be honest. Job searching these days can be awful. You find a job to apply for. You upload your resume. You then manually enter all of the information that is already on your resume...",
        DoIReallyNeedACoverLetterThumbnail,
        "cover-letter-for-a-tech-job",
        COVER_LETTER_FOR_TECH_JOB_CONTENT_MARKUP,
        "March 5, 2024",
        "CoverLetterForATechJob",
        false
    )
]