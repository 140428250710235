import React, {useState} from "react";
import {validateCoupon} from "../util/APIHelper";
import {ICONS} from "../util/IconHelper";

export const CouponContainer: React.FC<any> = ({setApprovedCouponCode, setMonthlyAmount}) => {
    const [showCouponCode, setShowCouponCode] = React.useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [couponText, setCouponText] = React.useState("");

    const checkCoupon = () => {
        setCouponText("Loading...")
        validateCoupon(couponCode.toUpperCase()).then(response => {
            if (response && response.data['isValid']) {
                setCouponText("Coupon Applied!")
                console.log(response.data)
                setMonthlyAmount(response.data['updatedPricingOptions'][0].price)
                setApprovedCouponCode(response.data['coupon'])
            } else {
                setCouponText("Coupon Invalid")
            }
        })
    }
    return (
        <div className={'coupon-container'}>
            {!showCouponCode && <div className={'coupon-question-text'} onClick={() => setShowCouponCode(true)}>Have a Coupon?</div>}
            {showCouponCode &&
                <>
                    <input
                        className={'coupon-code-input'}
                        maxLength={15}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <div className={'coupon-submit-right-arrow-container'} onClick={() => checkCoupon()}>
                        <ICONS.RIGHT_ARROW className={'coupon-submit-right-arrow'} />
                    </div>
                    {couponText && <div className={'coupon-code-text'}>{couponText}</div>}
                </>
            }
        </div>
    )}