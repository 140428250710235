import React, {useEffect} from 'react';
import "../css/GenericPage.css"
import {AllContent} from "../components/LearningPathContent";
import {DiscordJoin} from "../components/DiscordJoin";
import {useNavigate} from "react-router-dom";

export const DashboardPage: React.FC<any> = ({ isAuthenticated, setShowPremiumOverlay, userProgressData, setShowConfirmationOverlay, setUserProgressData }) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isAuthenticated || userProgressData === undefined || !userProgressData) {
            navigate("/");
            return;
        }

        if (!userProgressData.hasOnboarded) {
            navigate("/roadmap-builder");
        }
    }, [isAuthenticated, userProgressData, navigate]);

    return (
        <div className="page-container">
            <AllContent isAuthenticated={isAuthenticated} userProgressData={userProgressData} setShowConfirmationOverlay={setShowConfirmationOverlay} setUserProgressData={setUserProgressData} setShowPremiumOverlay={setShowPremiumOverlay} isLandingPage={false}/>
            {isAuthenticated && <DiscordJoin />}
        </div>
    );
};