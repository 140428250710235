import {GenericContent} from "../models/GenericContent";
import {ALL_CONTENT} from "../constants/LearningPathsConfig";

export function getContentFromParams(contentType: string | undefined, contentName: string | undefined): GenericContent | null {
    let foundContent = null
    switch (contentType) {
        case 'courses':
            ALL_CONTENT.allCourses.map(category => {
                category.content.map(content => {
                    if (content.navUrl === contentName) {
                        foundContent = content
                    }
                })
            });
            break;
        case 'interviews':
            ALL_CONTENT.allInterviews.map(category => {
                category.content.map(content => {
                    if (content.navUrl === contentName) {
                        foundContent = content
                    }
                })
            });
            break;
        case 'resources':
            ALL_CONTENT.allResources.map(category => {
                category.content.map(content => {
                    if (content.navUrl === contentName) {
                        foundContent = content
                    }
                })
            });
            break;
    }
    return foundContent
}