import DOM_AVATAR from "../images/testimonials/Dominik-Jasarevic-Avatar.webp"
import KAZ_AVATAR from "../images/testimonials/Shane-K-Avatar.webp"
import RYAN_AVATAR from "../images/testimonials/RyanTAvatar.webp"

export const TESTIMONIALS_CONFIG = {
    "header": "Don't Worry, You're In Good Company",
    "items": [
        {
            "name": "Ryan T.",
            "specialty": "Specialty: Web Development",
            "reviewText": "While there are many tutorials on how to learn new programming languages and technologies, there are very few about actually getting the job you've been practicing for. Beyond Code has been an invaluable tool at helping me tailor my resume and hone my interviewing skills to prepare me for a job in the tech industry",
            "rating": 5,
            "avatar": RYAN_AVATAR
        },
        {
            "name": "Shane K.",
            "specialty": "Specialty: Web Development",
            "reviewText": "The beyond code courses are extremely helpful when starting out your coding journey. They were very robust with knowledge and gave me a good grasp of what to do when applying for jobs, which languages I would enjoy to learn, and the in’s and out’s of a tech career from someone achieving great things in the industry.",
            "rating": 5,
            "avatar": KAZ_AVATAR
        },
        {
            "name": "Dominik Jasarevic",
            "specialty": "Specialty: Android Development",
            "reviewText": "Beyondcode.app has completely transformed my coding journey. I've not only learned effectively but also made great connections with fellow coders. The Discord community is like a big family where everyone is supportive and always ready to lend a helping hand.",
            "rating": 5,
            "avatar": DOM_AVATAR
        },
        // {
        //     "name": "Stamatis T.",
        //     "specialty": "Specialty: Web Development",
        //     "reviewText": "I'm grateful for the assistance Beyond Code has provided me with while preparing for interviews. The material was comprehensive yet easy to navigate, giving me the confidence to tackle any challenge thrown my way. Thanks to Beyond Code, I felt fully equipped to showcase my skills and secure opportunities. Highly recommend it for anyone seeking interview preparation!",
        //     "rating": 5,
        //     "avatar": null
        // }
    ]
}