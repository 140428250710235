import {GenericContent} from "./GenericContent";

export class Interview extends GenericContent {
    time: string;
    fileUrl: string;

    constructor(
        name: string,
        description: string,
        time: string,
        thumbnail: string,
        navUrl: string,
        fileUrl: string,
        trackingId: string,
        isPremium: boolean,
        alwaysInRoadmap: boolean
    ) {
        super(name, description, thumbnail, navUrl, trackingId, isPremium, alwaysInRoadmap);
        this.time = time;
        this.fileUrl = fileUrl;
    }
}