import axios from "axios";
import {getDefaultUserProgress, handleOnboardData} from "./UserProgressHelper";

const BASE_API_URL = "https://beyond-code-api.onrender.com"
const DEV_API_URL = "http://localhost:5002"

export const API_URL = (process.env.REACT_APP_ENV === 'production') ? BASE_API_URL : DEV_API_URL;



export async function userEmailEntered(email: string) {
    try {
        const response = await axios.post(`${API_URL}/auth/user-email-entered/`, {email});
        return response.data
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

export async function isUserPremium(userId: string) {
    try {
        const response = await axios.post(`${API_URL}/auth/get-user-info/`, {userId});
        console.log(response)
        return response.data
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

// export async function getInitialLandingPage(uid: string) {
//     try {
//         const response = await axios.post(`${API_URL}/auth/landing-page/?uid=${uid}`);
//         return response.data
//     } catch (error) {
//         console.error("Error fetching prices:", error);
//     }
// }

export async function getUserDashboard(userId: string) {
    try {
        const response = await axios.post(`${API_URL}/auth/dashboard-info/`, {userId});
        return response.data
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

export async function getPricingOptions() {
    try {
        return await axios.get(`${API_URL}/stripe/pricing-options/`);
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

export async function getOnboardingFlow() {
    try {
        return await axios.get(`${API_URL}/auth/onboarding-flow/`);
    } catch (error) {
        console.error("Error fetching prices:", error);
    }
}

export async function validateCoupon(code: String) {
    try {
        return await axios.post(`${API_URL}/stripe/validate-coupon/`, {code});
    } catch (error) {
        console.error("Error validating coupon:", error);
    }
}

export async function createNewCheckoutSession(coupon: string, successUrl: string, cancelUrl: string, uid: string, email: string) {
    try {
        return await axios.post(`${API_URL}/stripe/create-checkout-session/`, {
            coupon: coupon,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
            uid: uid,
            email: email
        });
    } catch (error) {
        console.error("Error creating checkout session:", error);
    }
}

export async function analyzeUserResume(file: File) {
    try {
        const formData = new FormData();
        formData.append('resume', file);
        const response = await axios.post(`${API_URL}/tools/analyze-resume/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response)
        return response.data;
    } catch (error) {
        console.error("Error uploading resume:", error);
    }
}

export async function generateInterviewQuestions(file: File, jobDescription: string) {
    try {
        const formData = new FormData();
        formData.append('resume', file);
        formData.append('jobDescription', jobDescription);
        const response = await axios.post(`${API_URL}/tools/generate-interview-questions/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response)
        return response.data;
    } catch (error) {
        console.error("Error uploading resume:", error);
    }
}

export async function onboardNewUser(onboardingAnswers: any, userId: string) {
    let onboardData = getDefaultUserProgress(userId);
    console.log(onboardingAnswers)
    let userData = handleOnboardData(onboardingAnswers, onboardData);
    console.log("Sending:")
    console.log(userData)
    try {
        const response = await axios.post(`${API_URL}/auth/set-user-onboarding/`, {userData});
        return response.data
    } catch (error) {
        console.error("Error validating coupon:", error);
    }
}

export async function updateRoadmap(type: string, id: string, userId: string, displayInRoadMap: boolean) {
    try {
        return await axios.post(`${API_URL}/auth/update-roadmap/`, {type: type, id: id, userId: userId, displayInRoadMap: displayInRoadMap});
    } catch (error) {
        console.error("Error validating coupon:", error);
    }
}

export async function updateItemCompleted(type: string, id: string, userId: string, hasCompleted: boolean) {
    try {
        return await axios.post(`${API_URL}/auth/update-completion/`, {type: type, id: id, userId: userId, hasCompleted: hasCompleted});
    } catch (error) {
        console.error("Error validating coupon:", error);
    }
}