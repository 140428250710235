import "../css/GenericComponents.css"
import React from "react";

export const BCButton: React.FC<any> = ({text, onClick, disabled=false}) => {
    return (
        <button className="bc-button" onClick={onClick} disabled={disabled}>{text}</button>
    )
}

export const HeaderSubheaderComponent: React.FC<any> = ({text, subtext, secondaryColorSubheader=false}) => (
    <div className={"header-text-container"}>
        <div className={"bc-header-text"}>{text}</div>
        {subtext && <div className={`bc-subheader-text ${secondaryColorSubheader ? 'secondary-color-subheader' : ''}`}>{subtext}</div>}
    </div>
)