import React, {useEffect, useState} from "react";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import OnboardingMapImage from "../../images/onboarding/onboarding-map.svg";

export const LoadingStep: React.FC<{ handleNext: any }> = ({handleNext}) => {

    const [showLoadingConfirmation, setShowLoadingConfirmation] = useState(false);
    const [loadingTime, setLoadingTime] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            let timer: NodeJS.Timeout | undefined;
            if (isLoading) {
                timer = setInterval(() => {
                    setLoadingTime((prev) => {
                        if (prev >= 10) {
                            clearInterval(timer);
                            setShowLoadingConfirmation(true);
                            setTimeout(() => {
                                setShowLoadingConfirmation(false);
                                handleNext()
                            }, 2000);
                            return 10;
                        }
                        return prev + 1;
                    });
                }, 1000);
            }
            return () => {
                if (timer) clearInterval(timer);
            };
        }, [isLoading]);

    return (
        <div className="onboarding-content-container">
            <div className="map-progress-circle">
                <CircularProgressbar
                    value={(loadingTime / 10) * 100}
                    styles={buildStyles({pathColor: "#3F81BF", trailColor: "#eaeaea"})}
                />
                <img src={OnboardingMapImage} alt="" className="map-icon-overlay"/>
            </div>
            {!showLoadingConfirmation ? (
                <>
                    <div className={"onboarding-loader-text"}>Creating your personal roadmap</div>
                    <div className="onboarding-loader-subtext">Just a moment...</div>
                </>
            ) : (
                <p className="confirmation-message">Ready!</p>
            )}
        </div>
    )
}