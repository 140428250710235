export abstract class GenericContent {
    name: string;
    description: string;
    thumbnail: string;
    navUrl: string;
    trackingId: string;
    isPremium: boolean;
    alwaysInRoadmap: boolean;

    protected constructor(name: string, description: string, thumbnail: string, navUrl: string, trackingId: string, isPremium: boolean, alwaysInRoadmap: boolean) {
        this.name = name;
        this.description = description;
        this.thumbnail = thumbnail;
        this.navUrl = navUrl;
        this.trackingId = trackingId;
        this.isPremium = isPremium;
        this.alwaysInRoadmap = alwaysInRoadmap;
    }
}