import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import "../css/RegisterPage.css";
import BackgroundImage from "../images/register-background.jpg";
import LogoMedium from "../images/Logo_Medium.webp";
import GoogleAuthButton from "../components/GoogleAuthButton";
import { setDoc, doc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { firestore } from "../util/Firebase";
import { FOOTER_CONFIG } from "../constants/FooterConfig";

export const RegisterPage: React.FC<any> = ({ isLogin }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get("email");
        if (emailParam) {
            setEmail(decodeURIComponent(emailParam));
        }
    }, [location.search]);

    const handleGoogleSuccess = async (user: any) => {
        try {
            await setDoc(doc(firestore, "users", user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName || "Anonymous",
                provider: "google",
                photoUrl: user.photoURL || null,
            }, { merge: true });
        } catch (error) {
            console.error("Error saving user data: ", error);
        }
        navigate("/dashboard");
    };

    const handleGoogleError = (error: any) => {
        console.error("Google sign-in error:", error);
    };

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const auth = getAuth();

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(firestore, "users", user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName || "Anonymous",
                provider: "email",
            });

            navigate("/dashboard");
        } catch (error: any) {
            console.error("Error during email/password sign-up: ", error);
            setError(error.message); // Show error message
        }
    };

    const header = isLogin ? "Welcome Back!" : "Almost there!";

    return (
        <div className="register-page-container">
            <img
                className={"login-header-logo"}
                src={LogoMedium}
                alt={"Beyond Code Logo"}
                onClick={() => navigate("/")}
            />
            <div className="form-container">
                <h1>{header}</h1>
                {!isLogin && <p>Create an account and build your Tech Career</p>}
                <div className="auth-buttons">
                    <GoogleAuthButton onSuccess={handleGoogleSuccess} onError={handleGoogleError} />
                </div>
                <div className="divider">or</div>
                <form onSubmit={handleSignUp}>
                    <div className="form-group">
                        <label htmlFor="email" className="visuallyhidden">Email</label>
                        <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="input-field"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className="visuallyhidden">Password</label>
                        <input
                            id="password"
                            type="password"
                            placeholder="Enter your password"
                            className="input-field"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </div>
                    <button type="submit" className="submit-button">Continue</button>
                    {error && <p className="error-message" role="alert">{error}</p>}
                </form>
                <div className={'register-page-bottom-info'}>
                    {!isLogin && <p className="login-text">Already have an account? <a href="/login">Log in</a></p>}
                    <p className="terms">By continuing, you agree to our <a href={FOOTER_CONFIG.privacyUrl}>Privacy Policy</a> and <a href={FOOTER_CONFIG.termsUrl}>Terms of Use</a>.</p>
                </div>
            </div>
            <div className="image-container">
                <img src={BackgroundImage} alt="Computer Image" />
            </div>
        </div>
    );
};