// Firebase.tsx
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import 'firebaseui/dist/firebaseui.css';
import "../css/Firebase.css";

const firebaseConfig = {
    apiKey: "AIzaSyBqeFOCVZfmfOqM0aMFsESYuKUpBCLVkLY",
    authDomain: "beyondcode-70c30.firebaseapp.com",
    projectId: "beyondcode-70c30",
    storageBucket: "beyondcode-70c30.appspot.com",
    messagingSenderId: "751029928365",
    appId: "1:751029928365:web:9df79853d432cabcb32124",
    measurementId: "G-KL9MF81ZML"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = (process.env.REACT_APP_ENV === 'production') ? getAnalytics(firebaseApp) : null;
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);