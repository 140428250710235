// GoogleAuthButton.tsx
import React from "react";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import "../css/GoogleAuthButton.css";
import GoogleIcon from "../images/google-icon.svg";

interface GoogleAuthButtonProps {
    onSuccess: (user: any) => void;
    onError?: (error: any) => void;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ onSuccess, onError }) => {
    const handleSignIn = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            onSuccess(user);
        } catch (error) {
            console.error("Google sign-in error: ", error);
            if (onError) {
                onError(error);
            }
        }
    };

    return (
        <button 
            className="auth-button google" 
            onClick={handleSignIn}
            type="button"
            aria-label="Sign in with Google account"
        >
            <img src={GoogleIcon} alt="Google logo" />
            <span>Sign in with Google</span>
        </button>
    );
};

export default GoogleAuthButton;