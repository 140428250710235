// OnboardingConfig.ts

import CurrentlyWorking from "../images/onboarding/currently-working.svg";
import ThinkingAboutProgramming from "../images/onboarding/think.svg";
import LearningToCode from "../images/onboarding/coding.svg";
import JobSearching from "../images/onboarding/job-searching.svg";
import AI from "../images/onboarding/ai.svg";
import CyberSecurity from "../images/onboarding/cyber-security.svg";
import Graduated from "../images/onboarding/graduated.svg";
import School from "../images/onboarding/school.svg";
import SelfTaught from "../images/onboarding/self-taught.svg";
import Transition from "../images/onboarding/transition.svg";
import Website from "../images/onboarding/website.svg";
import Phone from "../images/onboarding/phone.svg";
import Cloud from "../images/onboarding/cloud.svg";
import Unknown from "../images/onboarding/unkown.svg";
import OnboardingMapImage from "../images/onboarding/onboarding-map.svg";

// Map numeric values from API to images
const IMAGE_MAP: Record<number, string> = {
    0: CurrentlyWorking,
    1: ThinkingAboutProgramming,
    2: LearningToCode,
    3: JobSearching,
    4: AI,
    5: CyberSecurity,
    6: Graduated,
    7: School,
    8: SelfTaught,
    9: Transition,
    10: Website,
    11: Phone,
    12: Cloud,
    13: Unknown,
    14: OnboardingMapImage,
};

export enum OnboardingPageTypes {
    ICON_OVER_TEXT = "IconOverText",
    MULTIPLE_CHOICE_Q = "MultipleChoiceQ",
    TEXT_OVER_ICON = "TextOverIcon",
    TEXT_OVER_ROADMAP_PREVIEW = "TextOverPreview",
    LOADING_ROADMAP = "LoadingRoadmap",
    TRIAL_OVERVIEW = "TrialOverview",
    BEGIN_TRIAL = "BeginTrial"
}

export class OnboardingStep {
    type: OnboardingPageTypes;
    showProgressBar: boolean;
    text?: string;
    subtext?: string;
    icon?: string;
    answers?: any;
    buttonText?: string;
    secondaryColorSubheader?: boolean = false;
    displayCancelText?: boolean = false;
    iconSubText?: string;

    constructor(
        type: OnboardingPageTypes,
        showProgressBar: boolean,
        text?: string,
        subtext?: string,
        buttonText?: string,
        icon?: number,
        answers?: any,
        secondaryColorSubheader?: boolean,
        displayCancelText?: boolean,
        iconSubText?: string
    ) {
        this.type = type;
        this.showProgressBar = showProgressBar;
        this.text = text;
        this.subtext = subtext;
        this.icon = icon !== undefined ? IMAGE_MAP[icon] : undefined;
        if (answers && Array.isArray(answers.answers)) {
            this.answers = answers.answers.map((answer: any) => ({
                ...answer,
                icon: IMAGE_MAP[answer.icon],
            }));
        } else {
            this.answers = undefined;
        }

        this.buttonText = buttonText;
        this.secondaryColorSubheader = secondaryColorSubheader;
        this.displayCancelText = displayCancelText;
        this.iconSubText = iconSubText;
    }
}

export function parseOnboardingFlow(onboardingFlow: any[]): OnboardingStep[] {
    console.log(onboardingFlow);

    return onboardingFlow.map(step => new OnboardingStep(
        step.type,
        step.show_progress_bar,
        step.text,
        step.subtext,
        step.button_text,
        step.icon,
        step.answers,
        step.secondary_color_subheader,
        step.display_cancel_text,
        step.icon_sub_text
    ));
}
