import React, {useState} from 'react';
import "../css/GenericPage.css"
import "../css/PremiumOverlay.css"
import {ICONS} from "../util/IconHelper";
import {createNewCheckoutSession} from "../util/APIHelper";
import {createCheckoutSession} from "../util/Stripe";
import {Page, trackEvent, TRACKING_EVENTS} from "../util/TrackingHelper";
import {BCButton, HeaderSubheaderComponent} from "./BCButton";
import {CouponContainer} from "./CouponComponent";
import OnboardingMapImage from "../images/onboarding/onboarding-map.svg";

export const PremiumOverlay: React.FC<any> = ({user, setShowPremiumOverlay}) => {
    const [approvedCouponCode, setApprovedCouponCode] = React.useState("");

    const onOptionClicked = () => {
        trackEvent({page: Page.HEADER, event: TRACKING_EVENTS.PREMIUM_CHECKOUT_SESSION})
        createNewCheckoutSession(approvedCouponCode, window.location.origin, window.location.origin, user.uid, user.email).then(response => {
            if (response) {
                createCheckoutSession(response.data.sessionId)
            }
        })
    }

    return (
        <div className={'overlay-container'} onClick={() => setShowPremiumOverlay(false)}>
            <div className={`overlay-content premium-overlay-content`} onClick={(e) => e.stopPropagation()}>
                <PricingStep setApprovedCouponCode={setApprovedCouponCode}/>
                <BCButton text={"Start My Free Trial"} onClick={() => onOptionClicked()}/>
                <ICONS.CLOSE id={'premium-overlay-close-button'} className={'premium-options-close'} onClick={() => setShowPremiumOverlay(false)}/>
            </div>
        </div>
    );
};


export const PricingStep: React.FC<any> = ({setApprovedCouponCode}) => {
    const [monthlyAmount, setMonthlyAmount] = useState("29.99");
    return (
        <div className="onboarding-content-container pricing-section">
            <HeaderSubheaderComponent text={"Start Your 7-Day Free Trial"} subtext={`Then $${monthlyAmount} per month`} secondaryColorSubtext={true}/>
            <CouponContainer setApprovedCouponCode={setApprovedCouponCode} setMonthlyAmount={setMonthlyAmount}/>
            <img src={OnboardingMapImage} className="trial-image" alt=""/>
            <p>Let's Build Your Coding Career</p>
            <div className={"onboarding-pricing-cancel-text"}>Easily Cancel Anytime</div>
        </div>
    )
};