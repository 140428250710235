import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import "../css/Onboarding.css";
import {OnboardingPageTypes, OnboardingStep, parseOnboardingFlow} from "../constants/OnboardingConfig";
import { onboardNewUser, getOnboardingFlow } from "../util/APIHelper";
import { onBeginTrialClicked } from "../util/Stripe";
import { HeaderSubheaderComponent } from "../components/BCButton";
import { TrialSection } from "../components/onboarding/TrialSection";
import { OnboardingQuestion } from "../components/onboarding/MultipleChoiceSection";
import { RoadMapOverviewSection } from "../components/onboarding/RoadMapOverviewSection";
import { LoadingStep } from "../components/onboarding/LoadingSection";
import { CouponContainer } from "../components/CouponComponent";
import { OnboardingProgressBar } from "../components/onboarding/OnboardingProgressBar";
import { OnboardingNextButton } from "../components/onboarding/OnboardingNextButton";

export const OnboardingPage: React.FC<any> = ({ userProgressData, user, setUserProgressData }) => {
    const navigate = useNavigate();
    const [onboardingFlow, setOnboardingFlow] = useState<OnboardingStep[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentQuestionnaireIndex, setCurrentQuestionnaireIndex] = useState(0);
    const [approvedCouponCode, setApprovedCouponCode] = useState("");
    const [userAnswers, setUserAnswers] = useState<number[]>([]);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [monthlyAmount, setMonthlyAmount] = useState("29.99");

    useEffect(() => {
        if (userProgressData === undefined || !userProgressData) {
            navigate("/");
            return;
        }

        if (userProgressData.hasOnboarded && currentIndex === 0) {
            navigate("/dashboard");
            return;
        }

        async function fetchOnboardingFlow() {
            if (onboardingFlow.length) return
            const response = await getOnboardingFlow();
            if (response && response.data && !onboardingFlow.length) {
                const parsedFlow = parseOnboardingFlow(response.data.onboardingFlow);
                console.log(parsedFlow)
                setOnboardingFlow(parsedFlow);
                setUserAnswers(new Array(parsedFlow.filter(step => step.type === OnboardingPageTypes.MULTIPLE_CHOICE_Q).length).fill(null));
            }
        }
        fetchOnboardingFlow();
    }, [userProgressData, navigate]);

    const handleNext = () => {
        if (onboardingFlow[currentIndex].type === OnboardingPageTypes.MULTIPLE_CHOICE_Q) {
            setCurrentQuestionnaireIndex(currentQuestionnaireIndex + 1);
        }
        if (currentIndex < onboardingFlow.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            if (onboardingFlow[newIndex]?.type === OnboardingPageTypes.MULTIPLE_CHOICE_Q) {
                setNextDisabled(true);
            }
            if (onboardingFlow[newIndex].type === OnboardingPageTypes.LOADING_ROADMAP) {
                onboardNewUser(userAnswers, user.uid).then(r => {
                    setUserProgressData(r);
                });
            }
        } else if (currentIndex === onboardingFlow.length - 1) {
            onBeginTrialClicked(user);
        }
    };

    if (!onboardingFlow.length) return <div>Loading...</div>;

    const onboardingStep: OnboardingStep = onboardingFlow[currentIndex];

    return (
        <div className="onboarding-flow-container">
            <div className="onboarding-content-container">
                {onboardingStep.showProgressBar && (<OnboardingProgressBar currentIndex={currentIndex} onboardingFlow={onboardingFlow} />)}
                {onboardingStep.text && <HeaderSubheaderComponent text={onboardingStep.text} subtext={onboardingStep.subtext} secondaryColorSubheader={onboardingStep.secondaryColorSubheader} />}
                {onboardingStep.type === OnboardingPageTypes.BEGIN_TRIAL && <CouponContainer setApprovedCouponCode={setApprovedCouponCode} setMonthlyAmount={setMonthlyAmount} />}
                {onboardingStep.icon && <img src={onboardingStep.icon} className="onboarding-image" alt="Onboarding Icon" />}
                {onboardingStep.iconSubText && <div className={'onboarding-image-subtext'}>{onboardingStep.iconSubText}</div>}
                {onboardingStep.type === OnboardingPageTypes.MULTIPLE_CHOICE_Q && <OnboardingQuestion setUserAnswers={setUserAnswers} answers={onboardingStep.answers} currentQuestionnaireIndex={currentQuestionnaireIndex} setNextDisabled={setNextDisabled} />}
                {onboardingStep.type === OnboardingPageTypes.TEXT_OVER_ROADMAP_PREVIEW && <RoadMapOverviewSection userAnswers={userAnswers} />}
                {onboardingStep.type === OnboardingPageTypes.LOADING_ROADMAP && <LoadingStep handleNext={handleNext} />}
                {onboardingStep.type === OnboardingPageTypes.TRIAL_OVERVIEW && <TrialSection />}
                {onboardingStep.type !== OnboardingPageTypes.LOADING_ROADMAP && <OnboardingNextButton onboardingStep={onboardingStep} handleNext={handleNext} nextDisabled={nextDisabled} />}
            </div>
        </div>
    );
};
