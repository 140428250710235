import React, {useEffect, useState} from "react";
import "../css/TestimonialsModule.css";
import { FaStar } from "react-icons/fa";
import {TESTIMONIALS_CONFIG} from "../constants/TestimonialsConfig";

export const TestimonialsModule = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const testimonials = TESTIMONIALS_CONFIG.items;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [testimonials.length]);

    return (
        <div className="testimonials-module-container">
            <div className="testimonials-items-container">
                <Testimonial item={testimonials[currentIndex]} />
            </div>
            <div className="testimonial-circles-container">
                {testimonials.map((_, index) => (
                    <div
                        key={index}
                        className={`testimonial-circle ${currentIndex === index ? "active" : "inactive"}`}
                    ></div>
                ))}
            </div>
        </div>
    );
};

const Testimonial: React.FC<any> = ({item, index}) => {
    return (
        <div className={"testimonial-item-container"} key={index}>
            <div className={"testimonial-top-container"}>
                <div className={"testimonial-user-container"}>
                    {item.avatar && (
                        <img src={item.avatar} alt={`${item.name} avatar`}/>
                    )}
                    <div className={"testimonial-user-name-container"}>
                        <div id={'testimonial-name'}>{item.name}</div>
                        <div id={'testimonial-specialty'}>{item.specialty}</div>
                    </div>
                </div>
                <StarsDisplay rating={item.rating}/>
            </div>
            <p>{item.reviewText}</p>
        </div>
    )
}

const StarsDisplay: React.FC<any> = ({rating}) => {
    return (
        <div className={"testimonial-rating-container"}>
            {Array(rating).fill(null).map((_, i) => (
                <FaStar key={i} className="testimonial-star-icon"/>
            ))}
        </div>
    )
}