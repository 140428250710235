import React from "react";

export const OnboardingQuestion: React.FC<{setUserAnswers: any, answers: any, currentQuestionnaireIndex: number, setNextDisabled: any}> = ({setUserAnswers, answers, currentQuestionnaireIndex, setNextDisabled}) => {
    const [selectedAnswer, setSelectedAnswer] = React.useState(-1);

    const onAnswerSelected = (answerValue: number) => {
        setUserAnswers((prevAnswers: any) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[currentQuestionnaireIndex] = answerValue;
            return updatedAnswers;
        });
        setNextDisabled(false);
        setSelectedAnswer(answerValue)
    }

    return (
        <div className="option-buttons-container">
            {answers.map((answer: any) => {
                const {text, value, icon} = answer;
                const isSelected = selectedAnswer === value;
                return (
                    <button key={value} className={`option-button ${isSelected ? "active" : ""}`} onClick={() => onAnswerSelected(value)}>
                        <img src={icon} alt={text} className="option-button-icon"/>
                        {text}
                    </button>
                );
            })}
        </div>
    )
}