import React from "react";
import {FaMap, FaStar, FaUnlock} from "react-icons/fa";
import {MdNotificationsActive} from "react-icons/md";

export const TrialSection: React.FC = () => (
    <div className="trial-timeline">
        <TrialStep icon={FaMap} text={"Create your roadmap"}
                      subtext={"You've created a custom plan to building your coding career"} displayBar={true}
                      isCompleted={true}/>
        <TrialStep icon={FaUnlock} text={"Today: Try Beyond Code"}
                      subtext={"Access your roadmap and begin working towards your coding career"} displayBar={true}
                      isCompleted={true}/>
        <TrialStep icon={MdNotificationsActive} text={"Day 5: Trial Reminder"}
                      subtext={"We'll give you a heads up before your trial ends. Easily cancel anytime"}
                      displayBar={true} isCompleted={false}/>
        <TrialStep icon={FaStar} text={"Day 7: Trial Ends"} subtext={`Your subscription will start on ${getTrialEndDate()}`} displayBar={false} isCompleted={false}/>
    </div>
);

const TrialStep: React.FC<{ icon: React.ElementType; text: string; subtext: string, displayBar: boolean, isCompleted: boolean }> = ({icon: Icon, text, subtext, displayBar, isCompleted}) => {
    return (
        <div className="trial-item-section">
            <div className={`trial-item-icon-section ${isCompleted ? 'completed' : ''}`}>
                <Icon className="trial-item-icon"/>
                {displayBar && <div className={`trial-connector ${isCompleted ? 'completed' : ''}`}/>}
            </div>
            <div className="trial-text">
                <h3 className={`trial-text-header ${isCompleted ? 'completed' : ''}`}>{text}</h3>
                <p>{subtext}</p>
            </div>
        </div>
    );
};

export function getTrialEndDate() {
    const today = new Date();
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    const options: any = { month: 'long', day: 'numeric' };
    return nextWeek.toLocaleDateString('en-US', options);
}