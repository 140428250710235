import { Resource } from "../models/Resource";
import AdvancedDataStructuresThumbnail from "../images/resources/Advanced_Data_Structures.webp";
import AlgorithmsBigONotationThumbnail from "../images/resources/Algorithms and Big O Notation Guide.webp";
import BasicDataStructuresThumbnail from "../images/resources/Basic_Data_Structures.webp";
import DataTypesCheatSheetThumbnail from "../images/resources/Data Types Cheat Sheet.webp";
import GithubReferenceGuideThumbnail from "../images/resources/Github_Reference_Guide.webp";
import PythonCheatSheetThumbnail from "../images/resources/Python_Cheat_Sheet.webp";
import SQLCheatSheetThumbnail from "../images/resources/SQL_Cheat_Sheet.webp";
import ProgrammingOperatorsCheatSheetThumbnail from "../images/resources/Programming Operators Cheat Sheet.webp";
import ResumeCloudArchitectThumbnail from "../images/resources/Resume_ Cloud Architect, Mid-Sized Company.webp";
import ResumeMobileSoftwareEngineerMajorThumbnail from "../images/resources/Resume_ Mobile Software Engineer, Major Tech Company.webp";
import ResumeMobileSoftwareEngineerSmallThumbnail from "../images/resources/Resume_ Mobile Software Engineer, Small Sized Company.webp";
import ResumeSeniorSoftwareEngineerThumbnail from "../images/resources/Resume_ Senior Software Engineer, Mid-Sized Company.webp";
import SortingAndSearchingAlgorithmsThumbnail from "../images/resources/Sorting and Searching Algorithms Guide.webp";
import UltimateCodingInterviewGuideThumbnail from "../images/resources/The Ultimate Coding Interview Study Guide.webp";

export const ALL_RESOURCES = {
    GUIDES: [
        new Resource(
            "Github Reference Guide",
            "A guide to mastering version control with GitHub.",
            GithubReferenceGuideThumbnail,
            "github-reference-guide",
            "Git Cheat Sheet.pdf",
            "GitCheatSheet",
            true,
            true
        ),
        new Resource(
            "Basic Programming Data Structures",
            "Learn basic data structures in programming.",
            BasicDataStructuresThumbnail,
            "basic-programming-data-structures",
            "Programming Basic Data Structures.pdf",
            "BasicProgrammingDataStructures",
            true,
            true
        ),
        new Resource(
            "Sorting and Searching Algorithms Guide",
            "A guide on sorting and searching algorithms.",
            SortingAndSearchingAlgorithmsThumbnail,
            "sorting-and-searching-algorithms-guide",
            "Sorting and Searching.pdf",
            "SortingAndSearchingAlgorithmsGuide",
            true,
            false
        ),
        new Resource(
            "Algorithms and Big O Notation Guide",
            "Understand algorithms and Big O notation.",
            AlgorithmsBigONotationThumbnail,
            "algorithms-big-o-notation-guide",
            "Algorithms & Big O Notation.pdf",
            "AlgorithmsBigONotationGuide",
            true,
            false
        ),
        new Resource(
            "Advanced Data Structures",
            "Learn advanced data structures in programming.",
            AdvancedDataStructuresThumbnail,
            "advanced-data-structures",
            "Advanced Concepts and Data Structures.pdf",
            "AdvancedDataStructures",
            true,
            false
        ),
        new Resource(
            "The Ultimate Coding Interview Study Guide",
            "Prepare for coding interviews with this study guide.",
            UltimateCodingInterviewGuideThumbnail,
            "ultimate-coding-interview-guide",
            "Ultimate Coding Interview Study Guide.pdf",
            "UltimateCodingInterviewGuide",
            true,
            true
        )
    ],
    CHEAT_SHEETS: [
        new Resource(
            "Data Types Cheat Sheet",
            "A quick reference for common data types in programming.",
            DataTypesCheatSheetThumbnail,
            "data-types-cheat-sheet",
            "Data Types Cheat Sheet.pdf",
            "DataTypesCheatSheet",
            true,
            true
        ),
        new Resource(
            "Programming Operators Cheat Sheet",
            "A cheat sheet for common programming operators.",
            ProgrammingOperatorsCheatSheetThumbnail,
            "programming-operators-cheat-sheet",
            "Operators Cheat Sheet.pdf",
            "OperatorsCheatSheet",
            true,
            true
        ),
        new Resource(
            "Python Cheat Sheet",
            "A quick reference for common Python syntax.",
            PythonCheatSheetThumbnail,
            "python-cheat-sheet",
            "Python Cheat Sheet.pdf",
            "PythonCheatSheet",
            true,
            true
        ),
        new Resource(
            "SQL Cheat Sheet",
            "A cheat sheet for common SQL queries.",
            SQLCheatSheetThumbnail,
            "sql-cheat-sheet",
            "SQL Cheat Sheet.pdf",
            "SqlCheatSheet",
            true,
            true
        )
    ],
    RESUME_EXAMPLES: [
        new Resource(
            "Resume: Mobile Software Engineer, Small Sized Company",
            "A resume template for a Mobile Software Engineer at a small-sized company.",
            ResumeMobileSoftwareEngineerSmallThumbnail,
            "resume-mobile-software-engineer-small-company",
            "Mobile Software Engineer, Small Sized Company.pdf",
            "ResumeMobileSoftwareEngSmallCompany",
            true,
            true
        ),
        new Resource(
            "Resume: Mobile Software Engineer, Major Tech Company",
            "A resume template for a Mobile Software Engineer at a major tech company.",
            ResumeMobileSoftwareEngineerMajorThumbnail,
            "resume-mobile-software-engineer-major-company",
            "Mobile Software Engineer, Major Tech Company.pdf",
            "ResumeMobileSoftwareEngMajorCompany",
            true,
            true
        ),
        // new Resource(
        //     "Resume: UX Lead, Large Sized Company",
        //     "A resume template for a UX Lead at a large-sized company.",
        //     ResumeUXLeadLargeCompanyThumbnail,
        //     "resume-ux-lead-large-company",
        //     "UX Lead, Large Sized Company.pdf",
        //     "ResumeUxLeadLargeCompany",
        //     true
        // ),
        new Resource(
            "Resume: Cloud Architect, Mid-Sized Company",
            "A resume template for a Cloud Architect at a mid-sized company.",
            ResumeCloudArchitectThumbnail,
            "resume-cloud-architect-mid-sized-company",
            "Cloud Architect, Mid Sized Company.pdf",
            "ResumeCloudArchitectMidSizedCompany",
            true,
            true
        ),
        new Resource(
            "Resume: Senior Software Engineer, Mid-Sized Company",
            "A resume template for a Senior Software Engineer at a mid-sized company.",
            ResumeSeniorSoftwareEngineerThumbnail,
            "resume-senior-software-engineer-mid-sized-company",
            "Senior Software Engineer, Mid-Sized Company.pdf",
            "ResumeSeniorSoftwareEngMidSizedCompany",
            true,
            true
        )
    ]
}
