import {ToolType} from "../models/ToolType";
import ResumeIcon from "../images/AI Tool Resumes.webp";
import InterviewPrepIcon from "../images/AI Tool Interviewing Questions.webp"
import { IoMdSearch } from "react-icons/io";
import { FaRegListAlt } from "react-icons/fa";

export const RESUME_REVIEWER = 'RESUME_REVIEWER'
export const INTERVIEW_QUESTIONS = 'INTERVIEW_QUESTIONS'

export const TOOL_TYPES_CONFIG = [
    new ToolType(
        "resume-reviewer",
        "Resume Reviewer",
        "Create the ultimate programming resume",
        ResumeIcon,
        "resumeReviewer",
        true,
        RESUME_REVIEWER,
        [
            "Evaluates structure and clarity",
            "Provides personalized feedback",
            "Suggests relevant programming keywords",
            "Identifies potential gaps",
            "Recommends best practices"
        ],
        {
            "application/pdf": [".pdf"]
        },
        "Drag & drop your file here, or click to select files",
        "Resume Analyzer",
        IoMdSearch,
        "Analyze",
        "Analyzing your resume...",
        true
    ),
    new ToolType(
        "interview-questions",
        "Interview Questions",
        "Find the EXACT questions to prepare for",
        InterviewPrepIcon,
        "interviewQuestions",
        true,
        INTERVIEW_QUESTIONS,
        [
            "Evaluates structure and clarity",
            "Provides personalized feedback",
            "Suggests relevant programming keywords",
            "Identifies potential gaps",
            "Recommends best practices"
        ],
        {
            "application/pdf": [".pdf"]
        },
        "Drag & drop your resume here, or click to select files",
        "Interview Question Creator",
        FaRegListAlt,
        "Generate",
        "Generating questions...",
        true
    )
]