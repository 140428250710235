import {GenericContent} from "./GenericContent";
import type {IconType} from "react-icons";

export class ToolType extends GenericContent {

    navUrl: string;
    type: string;
    highlights: string[];
    uploaderFileTypes: { [key: string]: string[] };
    uploaderText: string;
    toolHeader: string;
    toolHeaderIcon: IconType;
    beginCtaText: string;
    inProgressText: string;

    constructor(
        navUrl: string,
        name: string,
        description: string,
        thumbnail: string,
        trackingId: string,
        isPremium: boolean,
        type: string,
        highlights: string[],
        uploaderFileTypes: { [key: string]: string[] },
        uploaderText: string,
        toolHeader: string,
        toolHeaderIcon: IconType,
        beginCtaText: string,
        inProgressText: string,
        alwaysInRoadmap: boolean
    ) {
        super(name, description, thumbnail, navUrl, trackingId, isPremium, alwaysInRoadmap);
        this.navUrl = navUrl;
        this.type = type;
        this.highlights = highlights;
        this.uploaderFileTypes = uploaderFileTypes;
        this.uploaderText = uploaderText;
        this.toolHeader = toolHeader;
        this.toolHeaderIcon = toolHeaderIcon;
        this.beginCtaText = beginCtaText;
        this.inProgressText = inProgressText;
    }
}