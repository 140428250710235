//src/auth/Stripe.ts
import initializeStripe from "./InitializeStripe";
import {createNewCheckoutSession} from "./APIHelper";

export async function createCheckoutSession(sessionId: string) {
    const stripe = await initializeStripe();
    if (stripe) {
        const { error } = await stripe.redirectToCheckout({sessionId: sessionId,});
        if (error) console.log(error)
    }
}

export const onBeginTrialClicked = (user: any) => {
    createNewCheckoutSession(
        "",
        "https://www.beyondcode.app/welcome",
        window.location.origin,
        user.uid,
        user.email
    ).then(response => {
        if (response) {
            createCheckoutSession(response.data.sessionId)
        }
    })
}