import React from "react";
import {GridItemIconInfo, RoadMapIcon} from "../LearningPathContent";
import {ICONS} from "../../util/IconHelper";
import {
    ONBOARDING_OVERVIEW_CURRENTLY_LEARNING,
    ONBOARDING_OVERVIEW_INTERESTED, ONBOARDING_OVERVIEW_JOB_SEARCHING
} from "../../constants/LearningPathsConfig";

export const RoadMapOverviewSection: React.FC<any> = (userAnswers: number[]) => {
    const selectedAnswer = userAnswers?.[0] ?? 0;

    const items = getExampleOverviewToDisplay(selectedAnswer)
    return (
        <div className={"onboarding-overview-section-items-list"}>
            {items.map((item, index) => (
                <OverviewItem item={item.content} type={item.type} />
            ))}
        </div>
    );
};

const OverviewItem: React.FC<any> = ({item, type}) => {
    return (
        <div className={`overview-item-content-container`}>
            <div className={'overview-item-image-container'}>
                <RoadMapIcon type={type}/>
                {type !== 'actions' &&
                    <img className={`overview-image ${type}`} src={item.thumbnail} alt={`${item.name} icon`}/>}
            </div>
            <div className={`overview-item-info-container`}>
                <h4>{item.name}</h4>
                <div className={"overview-item-icons-list-container"}>
                    {(type === "interviews" || type === "courses") && <GridItemIconInfo icon={ICONS.CLOCK} text={item.time}/>}
                    {type === "courses" && <GridItemIconInfo icon={ICONS.LESSONS} text={`${item.numberOfLessons} Lessons`}/>}
                    {type === "resources" && <GridItemIconInfo icon={ICONS.PDF} text="Download PDF"/>}
                    {type === "articles" && <GridItemIconInfo icon={ICONS.CALENDAR} text={item.date}/>}
                </div>
            </div>
        </div>
    );
};


function getExampleOverviewToDisplay(currentlyDescribesUserResponse: number) {
    switch (currentlyDescribesUserResponse) {
        case 0:
            return ONBOARDING_OVERVIEW_INTERESTED;
        case 1:
            return ONBOARDING_OVERVIEW_CURRENTLY_LEARNING;
        case 2:
            return ONBOARDING_OVERVIEW_JOB_SEARCHING;
        case 3:
            return ONBOARDING_OVERVIEW_JOB_SEARCHING;
    }
    return ONBOARDING_OVERVIEW_JOB_SEARCHING;
}