import {BCButton} from "../BCButton";
import React from "react";

export const OnboardingNextButton: React.FC<{onboardingStep: any, nextDisabled: boolean, handleNext: any}> = ({onboardingStep, nextDisabled, handleNext}) => {
    return (
        <div id={'onboarding-next-button'}>
            {onboardingStep.displayCancelText &&
                <div className={"onboarding-pricing-cancel-text"}>Easily Cancel Anytime</div>}
            <BCButton disabled={nextDisabled} text={onboardingStep.buttonText} onClick={handleNext}/>
        </div>
    )
}