// LearningPathsConfig.ts
import {TECH_CONCEPTS_COURSES, LEARNING_TO_CODE_COURSES, PROGRAMMER_RESUMES_COURSES, TECH_INTERVIEW_PREP_COURSES} from "./CoursesConfig";
import {ALL_INTERVIEWS} from "./InterviewsConfig";
import {ALL_RESOURCES} from "./ResourcesConfig";
import {Page} from "../util/TrackingHelper";
import {TOOL_TYPES_CONFIG} from "./ToolTypesConfig";

export const ALL_CONTENT = {
    allCourses: [
        {
            header: "Learning to Code",
            navUrl: "/learning-to-code/",
            pageTracking: Page.BECOMING_A_PROGRAMMER,
            content: LEARNING_TO_CODE_COURSES
        },
        {
            header: "Tech Concepts",
            navUrl: "/tech-concepts/",
            pageTracking: Page.TECH_CONCEPTS,
            content: TECH_CONCEPTS_COURSES
        },
        {
            header: "Job Searching",
            navUrl: "/job-searching/",
            pageTracking: Page.PROGRAMMING_RESUMES,
            content: PROGRAMMER_RESUMES_COURSES
        },
        {
            header: "Interview Prep",
            navUrl: "/tech-interview-prep/",
            pageTracking: Page.TECH_INTERVIEW_PREP,
            content: TECH_INTERVIEW_PREP_COURSES
        },
    ],
    allAITools: [
        {
            header: "AI Tools",
            content: TOOL_TYPES_CONFIG
        }
    ],
    allInterviews: [
        {
            header: "Formal Interviews",
            navUrl: "/formal-interviews/",
            content: ALL_INTERVIEWS.FORMAL_INTERVIEWS
        },
        {
            header: "Mock Interviews",
            navUrl: "/mock-interviews/",
            content: ALL_INTERVIEWS.MOCK_INTERVIEWS
        }
    ],
    allResources: [
        {
            header: "Guides",
            navUrl: "/guides/",
            content: ALL_RESOURCES.GUIDES
        },
        {
            header: "Cheat Sheets",
            navUrl: "/cheat-sheets/",
            content: ALL_RESOURCES.CHEAT_SHEETS
        },
        {
            header: "Resume Examples",
            navUrl: "/resume-examples/",
            content: ALL_RESOURCES.RESUME_EXAMPLES
        }
    ],
}

export const ALL_ACTION_ITEMS = {
    FIND_COURSE: {
        name: "Pick a specialty and find your first course",
        trackingId: "findCourse"
    },
    BUILD_FIRST_PROJECT: {
        name: "Build your first project (and save everything!)",
        trackingId: "buildFirstProject"
    },
    UPLOAD_GITHUB_PROJECT: {
        name: "Upload a project to GitHub",
        trackingId: "uploadProjectGithub"
    },
    BUILD_WITH_API: {
        name: "Build something that uses an API",
        trackingId: "buildWithAPI"
    },
    EXPLORE_LIBRARIES: {
        name: "Explore the most popular libraries/frameworks for your specialty",
        trackingId: "buildWithAPI"
    },
    WRITE_RESUME: {
        name: "Write a resume",
        trackingId: "writeResume"
    },
    WRITE_COVER_LETTER: {
        name: 'Write a "base" Cover Letter',
        trackingId: "writeCoverLetter"
    },
    PREPARE_INTERVIEW_QUESTIONS: {
        name: "Prepare your interview questions",
        trackingId: "prepareQuestions"
    }
}

export const ENTIRE_LEARNING_PATH = [
    {
        header: "1. Interested in Coding",
        content: [
            // Want to become a programmer? Start here
            {
                type: "courses",
                content: LEARNING_TO_CODE_COURSES[0]
            },
            // From Elementary School Teacher to Software Engineer
            {
                type: "interviews",
                content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[0]
            },
            // First programming language
            {
                type: "courses",
                content: LEARNING_TO_CODE_COURSES[1]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.FIND_COURSE
            }
        ]
    },
    {
        header: "2. Learning to Code",
        content: [
            // Data types cheat sheet
            {
                type: "resources",
                content: ALL_RESOURCES.CHEAT_SHEETS[0]
            },
            // Operators cheat sheet
            {
                type: "resources",
                content: ALL_RESOURCES.CHEAT_SHEETS[1]
            },
            // Python cheat sheet
            {
                type: "resources",
                content: ALL_RESOURCES.CHEAT_SHEETS[2],
            },
            // SQL Cheat sheet
            {
                type: "resources",
                content: ALL_RESOURCES.CHEAT_SHEETS[3]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.BUILD_FIRST_PROJECT
            },
            // Debugging
            {
                type: "courses",
                content: LEARNING_TO_CODE_COURSES[2]
            },
            // Basic Data Structures
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[1]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.EXPLORE_LIBRARIES
            }
        ]
    },
    {
        header: '3. Understanding Tech Concepts',
        content: [
            // APIs
            {
                type: "courses",
                content: TECH_CONCEPTS_COURSES[0]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.BUILD_WITH_API
            },
            // Github
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[0]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.UPLOAD_GITHUB_PROJECT
            },
            // From Community college
            {
                type: "interviews",
                content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[1]
            },
            // Project Management
            {
                type: "courses",
                content: TECH_CONCEPTS_COURSES[1]
            },
            // Basic Data Structures
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[1]
            },
            // Sorting and Searching Algorithms
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[2]
            },
            // Big O
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[3]
            },
            // Advanced Data Structures
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[4]
            },
        ]
    },
    {
        header: '4. Job Searching',
        content: [
            // Resume
            {
                type: "courses",
                content: PROGRAMMER_RESUMES_COURSES[1]
            },
            // Resume Ex 1
            {
                type: "resources",
                content: ALL_RESOURCES.RESUME_EXAMPLES[0]
            },
            // Resume Ex 2
            {
                type: "resources",
                content: ALL_RESOURCES.RESUME_EXAMPLES[1]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.WRITE_RESUME
            },
            // Resume Ex 3
            // {
            //     type: "resources",
            //     content: ALL_RESOURCES.RESUME_EXAMPLES[2]
            // },
            // Resume Ex 4
            // {
            //     type: "resources",
            //     content: ALL_RESOURCES.RESUME_EXAMPLES[3]
            // },
            // Resume Ex 5
            {
                type: "resources",
                content: ALL_RESOURCES.RESUME_EXAMPLES[4]
            },
            // Resume Reviewer
            {
                type: "tools",
                content: TOOL_TYPES_CONFIG[0]
            },
            // Cover Letter
            {
                type: "courses",
                content: PROGRAMMER_RESUMES_COURSES[2]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.WRITE_COVER_LETTER
            },
            // Portfolio
            {
                type: "courses",
                content: PROGRAMMER_RESUMES_COURSES[0]
            },
            // Finding Success
            {
                type: "interviews",
                content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[2]
            },
        ]
    },
    {
        header: '5. Interviewing',
        content: [
            // Coding Interview Study Guide
            {
                type: "resources",
                content: ALL_RESOURCES.GUIDES[5]
            },
            // Interview prep technical
            {
                type: "courses",
                content: TECH_INTERVIEW_PREP_COURSES[0]
            },
            // Interview prep nontechnical
            {
                type: "courses",
                content: TECH_INTERVIEW_PREP_COURSES[1]
            },
            {
                type: "actions",
                content: ALL_ACTION_ITEMS.PREPARE_INTERVIEW_QUESTIONS
            },
            // Mock entry
            {
                type: "interviews",
                content: ALL_INTERVIEWS.MOCK_INTERVIEWS[0]
            },
            // Mock mid
            {
                type: "interviews",
                content: ALL_INTERVIEWS.MOCK_INTERVIEWS[1]
            },
            // Mock diff career
            {
                type: "interviews",
                content: ALL_INTERVIEWS.MOCK_INTERVIEWS[2]
            },
            // Interview question
            {
                type: "tools",
                content: TOOL_TYPES_CONFIG[1]
            },
        ]
    }
]

export const ONBOARDING_OVERVIEW_INTERESTED = [
    {type: "courses", content: LEARNING_TO_CODE_COURSES[0]},
    {type: "interviews", content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[0]},
    {type: "courses", content: LEARNING_TO_CODE_COURSES[1]},
    {type: "resources", content: ALL_RESOURCES.CHEAT_SHEETS[0]},
    {type: "courses", content: LEARNING_TO_CODE_COURSES[2]},
]

export const ONBOARDING_OVERVIEW_CURRENTLY_LEARNING = [
    {type: "courses", content: LEARNING_TO_CODE_COURSES[2]},
    {type: "interviews", content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[1]},
    {type: "courses", content: PROGRAMMER_RESUMES_COURSES[1]},
    {type: "resources", content: ALL_RESOURCES.GUIDES[5]},
    {type: "courses", content: TECH_INTERVIEW_PREP_COURSES[0]}
]

export const ONBOARDING_OVERVIEW_JOB_SEARCHING = [
    {type: "courses", content: LEARNING_TO_CODE_COURSES[2]},
    {type: "interviews", content: ALL_INTERVIEWS.FORMAL_INTERVIEWS[2]},
    {type: "courses", content: PROGRAMMER_RESUMES_COURSES[1]},
    {type: "resources", content: ALL_RESOURCES.GUIDES[5]},
    {type: "courses", content: TECH_INTERVIEW_PREP_COURSES[0]}
]